import { useCallback, useContext, useMemo } from 'react';
import CompoundMetricsContext from '../../../../../../contexts/CompoundMetricsContext';

const useIsCompoundMetricDraftADuplicate = ({
  metricDraft,
}: {
  metricDraft: Metrics.CompoundMetric | undefined;
}) => {
  const { allCompoundMetrics } = useContext(CompoundMetricsContext);

  const isDuplicate = useCallback(
    ({ a, b }: { a: Metrics.CompoundMetric; b: Metrics.CompoundMetric }) => {
      const isExpressionEqual = a.expression === b.expression;

      return isExpressionEqual;
    },
    [],
  );

  const duplicates = useMemo((): Metrics.CompoundMetric[] => {
    if (!metricDraft) {
      return [];
    }

    return allCompoundMetrics
      .filter((metric) => metric.id !== metricDraft.id)
      .filter((metric) => isDuplicate({ a: metricDraft, b: metric }));
  }, [allCompoundMetrics, isDuplicate, metricDraft]);

  return {
    hasDuplicates: duplicates.length > 0,
    duplicates,
  };
};

export default useIsCompoundMetricDraftADuplicate;
