import React, { useContext } from 'react';
import SummaryTable from './SummaryTable';
import OrderDetailsContext from '../../../../contexts/OrderDetailsContext';

const SummaryTableContainer = () => {
  const { orderSummary } = useContext(OrderDetailsContext);

  if (!orderSummary) {
    return null;
  }
  return <SummaryTable orderSummary={orderSummary} />;
};

export default SummaryTableContainer;
