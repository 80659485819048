import Typography from '../../../../../kingpin/atoms/Typography';
import Card from '../../../../Common/Card';
import ListItemWrapper from '../../../../../screens/DataManager/DatasetDefinitions/ListItemWrapper';
import Column from '../../../../../screens/DataManager/DatasetDefinitions/Column';
import { Link } from 'react-router-dom';
import {
  buildShowTargetApp,
  buildShowWorkSpace,
} from '../../../../../navigation/appRoutes';
import React from 'react';
import { isWorkSpace } from '../../../../../isWorkSpace';

const WorkspacesUsage = ({
  workSpaces,
  usedId,
}: {
  workSpaces: (WorkSpace | TargetsApp.App)[];
  usedId: string;
}) => {
  return (
    <>
      {workSpaces.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: '8px' }}>
            <Typography.Header type="H5">{`Workspaces (${workSpaces.length})`}</Typography.Header>
          </div>
          <Card>
            {workSpaces.map((g, index) => (
              <ListItemWrapper
                key={`${g.id}-${usedId}`}
                isLast={workSpaces.length - 1 === index}
              >
                <Column
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <Link
                    to={
                      isWorkSpace(g)
                        ? buildShowWorkSpace(g.id)
                        : buildShowTargetApp(g.id, undefined)
                    }
                    target="_blank"
                    style={{ display: 'flex' }}
                  >
                    <Typography.Body type="Body 12" color="#0041ea">
                      {g.title}
                    </Typography.Body>
                  </Link>
                </Column>
              </ListItemWrapper>
            ))}
          </Card>
        </div>
      )}
    </>
  );
};

export default WorkspacesUsage;
