import React from 'react';
import styled from 'styled-components';
import TimelineTitleIcon from 'images/timeline-title.svg';
import TimelineEvent from './TimelineEvent';
import Heading from './Heading';
import FlexCentered from '../Common/FlexCentered';
import Card from '../Common/Card';
import Typography from '../../kingpin/atoms/Typography';

const List = styled.div`
  max-height: 40vh;
  overflow: auto;
`;

/**
 * Used in {@link OrderDetails} screen
 */
const OrderDetailsTimeline = ({
  events,
  isEmptyState,
}: {
  events: TimelineEvent[];
  isEmptyState: boolean;
}) => (
  <div style={{ marginBottom: 24 }}>
    <Heading label={'Timeline'} iconSrc={TimelineTitleIcon} />
    {isEmptyState && (
      <Card>
        <FlexCentered style={{ height: 200 }}>
          <Typography.Header type={'H3'}>Timeline not found</Typography.Header>
        </FlexCentered>
      </Card>
    )}
    {!isEmptyState && (
      <List className="hiding-scrollbar">
        {events.map((e) => (
          <TimelineEvent key={e.clientId} timelineEvent={e} />
        ))}
      </List>
    )}
  </div>
);

export default OrderDetailsTimeline;
