import { useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';
import MetricsContext from 'contexts/MetricsContext';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';
import getIsFilterEqual from 'components/VariableFiltersSlideOut/VariableFilterCard/getIsFilterEqual';
import fiToDrillDowns from './fiToDrillDowns';

const useDuplicationWarning = ({
  metricDraft,
}: {
  metricDraft: Metrics.NormalMetric | undefined;
}) => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const { allMetrics } = useContext(MetricsContext);

  const areFiltersEqual = useCallback(
    ({ a, b }: { a: Metrics.NormalMetric; b: Metrics.NormalMetric }) => {
      const aFixedFilters = fiToDrillDowns(a.filters, datasets);
      const bFixedFilters = fiToDrillDowns(b.filters, datasets);

      if (aFixedFilters.length !== bFixedFilters.length) {
        return false;
      }

      const aDatasetFilters = (a.datasetFilterIds || []).sort();
      const bDatasetFilters = (b.datasetFilterIds || []).sort();
      if (!_.isEqual(aDatasetFilters, bDatasetFilters)) {
        return false;
      }

      return (
        aFixedFilters.every((fA) =>
          bFixedFilters.some((fB) => getIsFilterEqual(fA, fB)),
        ) &&
        bFixedFilters.every((fB) =>
          aFixedFilters.some((fA) => getIsFilterEqual(fA, fB)),
        )
      );
    },
    [datasets],
  );

  const isDuplicate = useCallback(
    ({ a, b }: { a: Metrics.NormalMetric; b: Metrics.NormalMetric }) => {
      const isBaseEqual =
        a.dataType === b.dataType &&
        a.aggFunc === b.aggFunc &&
        a.field === b.field;

      return (
        isBaseEqual &&
        areFiltersEqual({
          a,
          b,
        })
      );
    },
    [areFiltersEqual],
  );

  const duplicates = useMemo((): Metrics.NormalMetric[] => {
    if (!metricDraft) {
      return [];
    }

    return allMetrics
      .filter((metric) => metric.id !== metricDraft.id)
      .filter((metric) => isDuplicate({ a: metricDraft, b: metric }));
  }, [allMetrics, isDuplicate, metricDraft]);

  return {
    hasDuplicates: duplicates.length > 0,
    duplicates,
  };
};

export default useDuplicationWarning;
