import React, { useContext } from 'react';
import OrderDetailsTimeline from './OrderDetailsTimeline';
import OrderDetailsContext from '../../contexts/OrderDetailsContext';

const OrderDetailsTimelineContainer = () => {
  const { events, isLoading } = useContext(OrderDetailsContext);
  const isEmptyState = !isLoading && events.length === 0;

  return <OrderDetailsTimeline events={events} isEmptyState={isEmptyState} />;
};

export default OrderDetailsTimelineContainer;
