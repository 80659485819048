import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useFieldDefinitions from '../../../hooks/useFieldDefinitions';
import _ from 'lodash';
import FilterPlateFormContext from '../../../contexts/FilterPlateFormContext';
import FilterPlatesContext from '../../../contexts/FilterPlatesContext';
import SingleItemPicker from '../../SingleItemPicker';
import toSentenceCase from '../../../services/toSentenceCase';
import VariableFiltersContext from '../../../contexts/VariableFiltersContext';
import { getDrillDownFieldName } from 'hooks/useDrillDownFieldName';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import DashboardContext from '../../../contexts/DashboardContext';
import isDashboardTemplate from '../../../types/isDashboardTemplate';
import Badge from 'components/Badge';
import SavedFiltersContext from 'screens/DataManager/DatasetFilters/SavedFilters/context/SavedFiltersContext';
import SavedFilterList from './SavedFilterList';

interface Option {
  key: string;
  label: string;
  value: any;
  renderRight?: JSX.Element;
}

export const isVariableDrillDown = (
  d: FleetOps.Field | DateBucketField | VariableDrillDownType | SavedFilter,
): d is VariableDrillDownType => {
  return 'type' in d && d.type === 'Variable';
};

const isSavedFilter = (
  d: FleetOps.Field | DateBucketField | VariableDrillDownType | SavedFilter,
): d is SavedFilter => {
  return 'type' in d && d.type === 'Dataset';
};

const FieldPickerContainer = () => {
  const { baseViews } = useContext(BaseViewsContext);
  const [searchText, setSearchText] = useState<string>('');
  const {
    dataTypes,
    drillDowns,
    scope,
    isSavedFiltersSelectorDisabled,
    fieldRedList,
  } = useContext(FilterPlatesContext);
  const { variableFilters } = useContext(VariableFiltersContext);
  const { dashboard } = useContext(DashboardContext);
  const { fieldDefinitions, isLoading } = useFieldDefinitions(dataTypes);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const { getDataTypeSavedFilters } = useContext(SavedFiltersContext);

  const savedDatasetFilters = getDataTypeSavedFilters(
    dataTypes || window.emptyArray,
  );

  const getBaseField = useCallback(
    (f: FleetOps.Field) => {
      if (!f.dataType) {
        return undefined;
      }

      const bv = baseViews[f.dataType];
      if (!bv) {
        return undefined;
      }

      return bv.fields[f.field];
    },
    [baseViews],
  );

  useEffect(() => {
    const allowedDefs = fieldDefinitions
      .filter((d) => d.field !== 'type' && d.field !== 'id')
      .filter((def) => {
        if (!fieldRedList) {
          return true;
        }

        return !fieldRedList.some((bannedField) => def.field === bannedField);
      });

    const baseDefs = allowedDefs.filter((f) => {
      const bf = getBaseField(f);
      if (!bf) {
        return true;
      }

      return bf.isVisible;
    });

    const variableOptions = (variableFilters || []).map((v) => ({
      key: v.id,
      label: getDrillDownFieldName(v.value, baseViews),
      value: v,
      renderRight: <Badge text="Variable Filter" badgeType={'Warning'} />,
    }));

    const defOptions = baseDefs
      .filter(
        (def) =>
          !variableOptions.some((vo) => vo.value.value.field === def.field),
      )
      .map((def) => {
        const bf = getBaseField(def);
        const alias = bf ? bf.nameAlias : def.alias;

        return {
          key: `${toSentenceCase(def.field)} - Fixed`,
          label: alias ? alias : def.field,
          value: def,
        };
      });

    const baseOptions = _.uniqBy([...defOptions], 'key');

    const unusedVariableOptions = variableOptions.filter(
      (vo) =>
        !drillDowns.some(
          (d) => d.type === 'Variable' && d.variableId === vo.value.id,
        ) &&
        !scope.some(
          (d) => d.type === 'Variable' && d.variableId === vo.value.id,
        ),
    );

    const pickerOptions = (() => {
      if (isDashboardTemplate(dashboard)) {
        return _.sortBy([...baseOptions, ...unusedVariableOptions], 'label');
      } else {
        return _.sortBy([...baseOptions], 'label');
      }
    })();

    const newFilteredOptions = (() => {
      if (searchText === '') {
        return pickerOptions;
      }

      return pickerOptions.filter((o) =>
        o.label.toLowerCase().includes(searchText.toLowerCase()),
      );
    })();

    setFilteredOptions(newFilteredOptions);
  }, [
    baseViews,
    fieldDefinitions,
    getBaseField,
    dashboard,
    searchText,
    variableFilters,
    scope,
    drillDowns,
    fieldRedList,
  ]);

  const { onFieldSelected, onVariableFilterAdded, onSavedFilterAdded } =
    useContext(FilterPlateFormContext);

  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onOptionSelected = (
    field:
      | FleetOps.Field
      | DateBucketField
      | VariableDrillDownType
      | SavedFilter,
  ) => {
    if (isVariableDrillDown(field)) {
      onVariableFilterAdded(field);
    } else if (isSavedFilter(field)) {
      onSavedFilterAdded(field);
    } else {
      onFieldSelected(field);
    }
  };

  return (
    <SingleItemPicker
      testId="drill-down-field-picker"
      options={filteredOptions}
      onOptionSelected={onOptionSelected}
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      isLoading={isLoading}
      renderBeforeOptions={
        isSavedFiltersSelectorDisabled ? undefined : (
          <SavedFilterList
            onOptionSelected={onOptionSelected}
            savedDatasetFilters={savedDatasetFilters}
          />
        )
      }
    />
  );
};

export default FieldPickerContainer;
