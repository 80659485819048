import React, { useContext, useMemo } from 'react';
import useGetBoardUsageReport from './useGetBoardUsageReport';
import BoardContext from '../../../../../contexts/BoardContext';
import ErrorBoundary from '../../../../Common/ErrorBoundary';
import WorkspacesUsage from './WorkspacesUsage';
import EntityAppUsage from './EntityAppUsage';
import DashboardGadgetUsage from '../../../../../screens/DataManager/Metrics/MetricSlideOut/UsageTab/DashboardGadgetUsage';
import ScorecardUsage from '../../../../../screens/DataManager/Metrics/MetricSlideOut/UsageTab/ScorecardUsage';
import GoalsUsage from '../../../../../screens/DataManager/Metrics/MetricSlideOut/UsageTab/Goals';

const BoardUsageReport = () => {
  const { board } = useContext(BoardContext);
  const getBoardUsageReport = useGetBoardUsageReport();
  const report = useMemo(() => {
    return getBoardUsageReport(board.id);
  }, [board, getBoardUsageReport]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <DashboardGadgetUsage
        dashboardGadgets={report.dashboardGadgets}
        usedId={board.id}
      />
      <ScorecardUsage scorecards={report.scorecards} usedId={board.id} />
      <GoalsUsage goals={report.goals} usedId={board.id} />
      <WorkspacesUsage workSpaces={report.workSpaces} usedId={board.id} />
      <EntityAppUsage entities={report.entities} usedId={board.id} />
    </div>
  );
};

const Gate = () => {
  return (
    <ErrorBoundary isHiddenError>
      <BoardUsageReport />
    </ErrorBoundary>
  );
};

export default Gate;
