import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Colors from 'theme/colors';
import { Loader } from '@googlemaps/loader';
import StopIcon from 'images/timeline-stop.svg';

import OrderDetailsMap from './OrderDetailsMap';
import OrderDetailsContext from '../../contexts/OrderDetailsContext';
import isStop from '../../isStop';
import ErrorBoundary from '../Common/ErrorBoundary';
import FlexCentered from '../Common/FlexCentered';
import Typography from '../../kingpin/atoms/Typography';
import Card from 'components/Common/Card/Card';

const getFeature = (e: TimelineEvent, id: string): any => {
  return {
    type: 'Feature',
    id,
    geometry: isStop(e.event) ? e.event.point : e.event.line,
  };
};

/**
 * Process each point in a Geometry, regardless of how deep the points may lie.
 * @param {google.maps.Data.Geometry} geometry The structure to process
 * @param {function(google.maps.LatLng)} callback A function to call on each
 *     LatLng point encountered (e.g. Array.push)
 * @param {Object} thisArg The value of 'this' as provided to 'callback' (e.g.
 *     myArray)
 */
const processPoints = (
  geometry: google.maps.Data.Geometry,
  callback: (g: google.maps.LatLng) => void,
  thisArg: any,
) => {
  // eslint-disable-next-line no-undef
  if (geometry instanceof google.maps.LatLng) {
    callback.call(thisArg, geometry);
    // eslint-disable-next-line no-undef
  } else if (geometry instanceof google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    // @ts-ignore
    geometry.getArray().forEach(function (g) {
      processPoints(g, callback, thisArg);
    });
  }
};

const OrderDetailsMapContainer = ({ height }: { height?: number }) => {
  const mapDivRef = useRef<HTMLDivElement>(null);
  const { events, selectedEvent, isLoading } = useContext(OrderDetailsContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState<google.maps.Map<HTMLElement>>();
  const [loader] = useState<Loader>(
    new Loader({
      apiKey: 'AIzaSyA2bn2XQYAZKfJFYcgxTNL3X8O1qUJtmdo',
      version: 'quarterly', // This channel is updated once per quarter
      libraries: [],
    }),
  );

  const getIsVisible = useCallback(
    (id: string) => {
      if (!selectedEvent) {
        return true;
      }

      const selectedEventIndex = events.findIndex(
        (e) => e.clientId === selectedEvent.clientId,
      );
      const timelineEventIndex = events.findIndex((e) => e.clientId === id);
      if (timelineEventIndex === -1) {
        return false;
      }
      const isSelected = id === selectedEvent.clientId;
      if (isSelected) {
        return true;
      }

      if (!isStop(selectedEvent.event)) {
        // If the selected event is a movement
        if (
          selectedEventIndex - 1 === timelineEventIndex ||
          selectedEventIndex + 1 === timelineEventIndex
        ) {
          // and this event is a stop before of after it
          return true;
        }
      } else {
        // if the selected event is a stop
        if (selectedEventIndex - 1 === timelineEventIndex) {
          // and this event is the movement before it
          return true;
        }
      }

      return false;
    },
    [events, selectedEvent],
  );

  useEffect(() => {
    if (!map) {
      return;
    }
    if (!selectedEvent) {
      map.data.forEach((f) => {
        map.data.overrideStyle(f, { visible: true });
      });
    }

    if (selectedEvent) {
      map.data.forEach((f) => {
        const isVisible = getIsVisible(f.getId() as string);
        map.data.overrideStyle(f, { visible: isVisible });
      });
    }
  }, [getIsVisible, map, selectedEvent]);

  const zoom = useCallback(() => {
    if (!map) {
      return;
    }

    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds();
    map.data.forEach((f) => {
      processPoints(f.getGeometry(), bounds.extend, bounds);
    });
    map.fitBounds(bounds);
  }, [map]);

  useEffect(() => {
    if (!map) {
      return;
    }
    // Clear old items
    map.data.forEach((d) => {
      map.data.remove(d);
    });

    map.data.setStyle({
      fillColor: 'pink',
      strokeColor: Colors.NAVY_BLUE,
      strokeWeight: 5,
      strokeOpacity: 0.7,
      icon: StopIcon,
    });
    events.forEach((e) => {
      map.data.addGeoJson(getFeature(e, e.clientId));
    });
    zoom();
  }, [events, map, zoom]);

  const isEmptyState = events.length === 0 && !isLoading;

  useEffect(() => {
    if (isEmptyState) {
      setMap(undefined);
      return;
    }

    if (mapDivRef.current !== null && !map) {
      loader.load().then(() => {
        const elem = document.getElementById('map');
        if (!elem) {
          throw new Error('Map element not found');
        }

        // eslint-disable-next-line no-undef
        const m = new google.maps.Map(elem, {
          center: {
            lat: 0,
            lng: 0,
          },
          zoom: 12,
        });

        setMap(m);
      });
    }
  }, [isEmptyState, events, loader, map, zoom]);

  if (isEmptyState) {
    /**
     * Some funky stuff with height here...
     * On {@link DetailsSlideOutGate}, there is an opinion on how tall this should be
     * so it injects a height
     *
     * This concern is not on the {@link OrderDetails}, which expects flex
     * sizing on the parent
     */
    return (
      <Card style={{ marginBottom: 16, height: height ? undefined : '100%' }}>
        <FlexCentered style={{ height: height ? height : '100%' }}>
          <Typography.Header type={'H3'}>
            Coordinates not found
          </Typography.Header>
        </FlexCentered>
      </Card>
    );
  }

  return <OrderDetailsMap mapDivRef={mapDivRef} height={height} />;
};

const Gate = ({ height }: { height?: number }) => {
  const { events } = useContext(OrderDetailsContext);
  const [hasError, setHasError] = useState<boolean>(false);
  const onErrorCallback = () => {
    setHasError(true);
  };

  const key = hasError
    ? events
        .map(
          (e) => `${e.driver}-${e.trailer}-${e.truck}-${e.event}-${e.clientId}`,
        )
        .join('--')
    : undefined;

  return (
    <ErrorBoundary onErrorCallback={onErrorCallback} key={key}>
      <OrderDetailsMapContainer height={height} />
    </ErrorBoundary>
  );
};

export default Gate;
