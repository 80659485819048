import { useContext } from 'react';

import Alert from 'kingpin/Alert';
import Typography from 'kingpin/atoms/Typography';
import { PortalsContext } from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import { getMetricLink } from '../../../../AllSets/routes';

import useIsCompoundMetricDraftADuplicate from './useIsCompoundMetricDraftADuplicate';

const DuplicationWarning = ({
  duplicates,
}: {
  duplicates: Metrics.CompoundMetric[];
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  return (
    <div style={{ width: '100%' }}>
      {duplicates.map((metric) => (
        <div key={metric.id} style={{ marginBottom: 4 }}>
          <a
            target={'_blank'}
            href={getMetricLink({
              metric: metric,
              portal: selectedPortal,
            })}
            rel="noreferrer"
          >
            <Typography.Body type={'Link'}>{metric.name}</Typography.Body>
          </a>
        </div>
      ))}
    </div>
  );
};

const CompoundMetricDuplicationWarning = ({
  metricDraft,
}: {
  metricDraft: Metrics.CompoundMetric | undefined;
}) => {
  const { hasDuplicates, duplicates } = useIsCompoundMetricDraftADuplicate({
    metricDraft,
  });

  if (!hasDuplicates) {
    return null;
  }
  return (
    <div style={{ marginTop: '24px' }}>
      <Alert
        type={'Warning'}
        title={`Duplicate Metric${duplicates.length > 1 ? 's' : ''} found`}
        body={<DuplicationWarning duplicates={duplicates} />}
      />
    </div>
  );
};

export default CompoundMetricDuplicationWarning;
