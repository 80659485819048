import React, { ChangeEvent, useContext } from 'react';
import styled from 'styled-components';

import { UserInvitationRow } from './types';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';

import Inputs from '../Inputs';
import AssetPicker from '../Inputs/AssetPicker';
import emailRegex from './emailRegex';
import Warning from '../Warning';
import useGrantablePermissions from './useGrantablePermissions';
import getPermissionLabel from './getPermissionLabel';
import USER_ROLES from '../../roles';
import Button from '../../kingpin/atoms/Button';
import Form from '../../kingpin/forms/Form';
import FormHeader from '../../kingpin/forms/FormHeader';
import FormContent from '../../kingpin/forms/FormContent';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const EmailCol = styled.div`
  flex: 2;
  margin-right: 35px;
`;

const RoleCol = styled.div`
  flex: 1;
  margin-right: 35px;
`;

const AssetsCol = styled.div`
  flex: 1;
  margin-right: 16px;
  min-width: 150px;
`;

const RemoveCol = styled.div`
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PermissionsHeadings = () => {
  const { dataPermissions } = useGrantablePermissions({
    isUserShowScreen: false,
  });

  return (
    <>
      {dataPermissions
        .map((p) => p.permission)
        .map((p) => (
          <RoleCol key={p}>{getPermissionLabel(p)}</RoleCol>
        ))}
    </>
  );
};

const PermissionsInputs = ({
  updateRow,
  row,
}: {
  updateRow: (row: UserInvitationRow) => void;
  row: UserInvitationRow;
}) => {
  const { dataPermissions } = useGrantablePermissions({
    isUserShowScreen: false,
  });
  const isDataPermissionsEnabledByDefault = row.roles.some(
    (r) => r.id === USER_ROLES.ADMIN || r.id === USER_ROLES.EDITOR,
  );

  return (
    <>
      {dataPermissions
        .map((p) => p.permission)
        .map((p) => (
          <RoleCol key={`${p}-${row.id}`}>
            <Inputs.Checkbox
              isDisabled={isDataPermissionsEnabledByDefault}
              isChecked={
                isDataPermissionsEnabledByDefault || row.permissions.includes(p)
              }
              onToggled={(newValue) => {
                if (newValue) {
                  const newRow = {
                    ...row,
                    permissions: [...row.permissions, p],
                  };
                  updateRow(newRow);
                } else {
                  const newRow = {
                    ...row,
                    permissions: row.permissions.filter((p2) => p2 !== p),
                  };
                  updateRow(newRow);
                }
              }}
            />
          </RoleCol>
        ))}
    </>
  );
};

const InviteUsersForm = ({
  rows,
  roles,
  onAddAnotherClicked,
  onRemoveClicked,
  updateRow,
  sendInvites,
  isLoading,
  workSpaces,
  reports,
  dashboards,
  isSendDisabled,
  isShowingWarning,
  warningContent,
  close,
  isAtSeatLimit,
}: {
  rows: UserInvitationRow[];
  roles: FleetOps.RoleDefinition[];
  onAddAnotherClicked: () => void;
  onRemoveClicked: (row: UserInvitationRow) => void;
  updateRow: (row: UserInvitationRow) => void;
  sendInvites: () => void;
  isLoading: boolean;
  workSpaces: (WorkSpace | TargetsApp.App)[];
  reports: ReportType[];
  dashboards: DashboardType[];
  isSendDisabled: boolean;
  isShowingWarning: boolean;
  warningContent: string[];
  close: () => void;
  isAtSeatLimit: boolean;
}) => {
  const { isPortalsEnabled } = useContext(PortalsContext);

  return (
    <Form>
      <FormHeader title={'Invite New Users'} onClose={close} />
      <FormContent>
        <Row style={{ marginBottom: 4 }}>
          <EmailCol>
            <Typography.Body type="Label">Email</Typography.Body>
          </EmailCol>
          <RoleCol>
            <Typography.Body type="Label">Role</Typography.Body>
          </RoleCol>
          <PermissionsHeadings />
          {!isPortalsEnabled && (
            <AssetsCol>
              <Typography.Body type="Label">Assets</Typography.Body>
            </AssetsCol>
          )}

          <RemoveCol />
        </Row>
        {rows.map((row, index) => (
          <Row
            key={row.id}
            style={{ marginBottom: 24 }}
            data-testid={`input-row-${index}`}
          >
            <EmailCol>
              <Inputs.TextInput
                state={!emailRegex.test(row.email) ? 'Error' : undefined}
                data-testid={`email-input-${index}`}
                value={row.email}
                placeholder={'Jimmy@example.com'}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  updateRow({
                    ...row,
                    email: event.target.value.trim(),
                  });
                }}
              />
            </EmailCol>
            <RoleCol>
              <Inputs.Dropdown
                data-testid={`role-input-${index}`}
                selectedLabel={row.roles.filter((r) => r.visible)[0].label}
                options={roles
                  .filter((r) => r.visible)
                  .map((r) => ({
                    label: r.label,
                    id: r.id,
                    onSelected: () => {
                      const newRow = {
                        ...row,
                        roles: [r],
                      };
                      updateRow(newRow);
                    },
                  }))}
              />
            </RoleCol>
            <PermissionsInputs row={row} updateRow={updateRow} />
            {!isPortalsEnabled && (
              <AssetsCol>
                <AssetPicker
                  dashboards={dashboards}
                  workSpaces={workSpaces}
                  reports={reports}
                  selectedDashboardIds={row.assets.dashboardIds}
                  selectedReportIds={row.assets.reportIds}
                  selectedWorkSpaceIds={row.assets.workSpaceIds}
                  setSelectedDashboardIds={(ids) => {
                    updateRow({
                      ...row,
                      assets: {
                        ...row.assets,
                        dashboardIds: ids,
                      },
                    });
                  }}
                  setSelectedReportIds={(ids) => {
                    updateRow({
                      ...row,
                      assets: {
                        ...row.assets,
                        reportIds: ids,
                      },
                    });
                  }}
                  setSelectedWorkSpaceIds={(ids) => {
                    updateRow({
                      ...row,
                      assets: {
                        ...row.assets,
                        workSpaceIds: ids,
                      },
                    });
                  }}
                  clearSelection={() => {
                    updateRow({
                      ...row,
                      assets: {
                        workSpaceIds: [],
                        reportIds: [],
                        dashboardIds: [],
                      },
                    });
                  }}
                />
              </AssetsCol>
            )}

            <RemoveCol>
              {rows.length > 1 && !isLoading && (
                <Button
                  size={'Small'}
                  type={'Tertiary'}
                  icon={'cross'}
                  testId="remove-button"
                  onClick={() => onRemoveClicked(row)}
                />
              )}
            </RemoveCol>
          </Row>
        ))}
      </FormContent>
      {!isLoading && (
        <div style={{ marginBottom: 12, width: 200 }}>
          <Button
            type="Ghost"
            size="Small"
            onClick={onAddAnotherClicked}
            testId={'add invitation'}
            icon="add"
            label={'Add another invitation'}
            isDisabled={isAtSeatLimit}
            isDisabledMessage={'You have no seats remaining'}
          />
        </div>
      )}
      {isShowingWarning && (
        <Warning warnings={warningContent} isFirstLineBold />
      )}
      <Row style={{ justifyContent: 'flex-end', marginBottom: 32 }}>
        <Button
          type="Primary"
          size="Small"
          onClick={sendInvites}
          isLoading={isLoading}
          isDisabled={isLoading || isSendDisabled}
          label={rows.length === 1 ? 'Send Invitation' : 'Send Invitations'}
        />
      </Row>
    </Form>
  );
};

export default InviteUsersForm;
