import React from 'react';

export interface ImpersonatorContextType {
  impersonatorId?: string;
  startImpersonationAs: (userId: string) => void;
  stopImpersonation: () => void;
  viewingAppAs?: UserManagement.SignedUpUser | UserManagement.PendingUser;
}

const ImpersonatorContext = React.createContext<ImpersonatorContextType>(
  {} as ImpersonatorContextType,
);

export default ImpersonatorContext;
