import React, { useContext } from 'react';
import OrderDetailsComments from './OrderDetailsComments';
import OrderDetailsContext from '../../contexts/OrderDetailsContext';

const OrderDetailsCommentsContainer = () => {
  const { orderNumber } = useContext(OrderDetailsContext);
  return <OrderDetailsComments orderNumber={orderNumber} />;
};

export default OrderDetailsCommentsContainer;
