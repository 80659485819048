export const STAFF_USERS: UserManagement.FleetOpsStaffUser[] = [
  {
    id: 'staff-1',
    displayName: 'FleetOps Admin',
    email: 'mark@fleetops.com',
    type: 'FleetOpsStaff',
    isDeleted: false,
    createdOn: '2018-06-01',
    ui: 'legacy',
  },
  {
    id: 'staff-2',
    displayName: 'FleetOps Admin',
    email: 'ross@fleetops.com',
    type: 'FleetOpsStaff',
    isDeleted: false,
    createdOn: '2019-05-01',
    ui: 'legacy',
  },
];

/**
 *  Note: USER-1 is an admin.
 *
 *  @see getUserProfilesRef.ts (mocked) to understand what permissions
 *  these users will have.
 */
export const MOCK_USERS: UserManagement.SignedUpUser[] = [
  {
    id: 'USER-1',
    displayName: 'Andrew',
    type: 'SignedUp',
    email: 'andres@fleetops.com',
    isDeleted: false,
    createdOn: '2019-05-01',
    ui: 'legacy',
  },
  {
    id: 'USER-2',
    displayName: 'Brittney',
    type: 'SignedUp',
    email: 'brittney@fleetops.com',
    isDeleted: false,
    createdOn: '2020-05-01',
    ui: 'legacy',
  },
  {
    id: 'USER-3',
    displayName: 'Carl the Admin',
    type: 'SignedUp',
    email: 'carl@fleetops.com',
    isDeleted: false,
    createdOn: '2020-02-01',
    ui: 'legacy',
  },
  {
    id: 'USER-4',
    displayName: 'Dee the User',
    type: 'SignedUp',
    email: 'dee@fleetops.com',
    isDeleted: false,
    createdOn: '2021-01-21',
    ui: 'legacy',
  },
  {
    id: 'USER-5',
    displayName: 'Edward the mapped engagement user',
    type: 'SignedUp',
    email: 'edward@fleetops.com',
    isDeleted: false,
    createdOn: '2024-10-31',
    ui: 'legacy',
  },
  {
    id: 'USER-6',
    displayName: 'Frank the migrating user',
    type: 'SignedUp',
    email: 'frank@fleetops.com',
    isDeleted: false,
    createdOn: '2024-11-12',
    ui: 'portals',
  },
  {
    id: 'USER-7',
    displayName: 'Greg Mapped User',
    type: 'SignedUp',
    email: 'greg@fleetops.com',
    isDeleted: false,
    createdOn: '2024-11-31',
    ui: 'legacy',
  },
  {
    id: 'USER-8',
    displayName: 'Harry Double Mapped User',
    type: 'SignedUp',
    email: 'harry@fleetops.com',
    isDeleted: false,
    createdOn: '2025-01-05',
    ui: 'portals',
  },
  {
    id: 'USER-9',
    displayName: 'Ian Double Mapped User via two portals',
    type: 'SignedUp',
    email: 'ian@fleetops.com',
    isDeleted: false,
    createdOn: '2025-03-26',
    ui: 'portals',
  },
];
