import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import usePopup from '../../hooks/usePopup';
import Button from '../../kingpin/atoms/Button';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import LocalTimelineContext from '../../contexts/Timeline/LocalTimelineContext';
import Form from '../../kingpin/forms/Form';
import FormHeader from '../../kingpin/forms/FormHeader';
import FormContent from '../../kingpin/forms/FormContent';
import Typography from '../../kingpin/atoms/Typography';
import TextInput from '../../kingpin/atoms/TextInput';
import Row from '../Common/Row';
import Event from './Event';
import getTimeStamp from '../../getTimeStamp';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import ContentEditable from 'react-contenteditable';
import Colors2 from '../../theme/Colors2';

const AddEventForm = ({
  close,
  addEvent,
}: {
  close: () => void;
  addEvent: ({
    actionText,
    contentText,
    contextText,
    interaction,
    isContentTextRich,
    destinationOverride,
  }: {
    actionText: string;
    contextText: string;
    contentText?: string;
    interaction?: Timeline.Interaction;
    isContentTextRich?: boolean;
    destinationOverride?: Timeline.EventSource;
  }) => Promise<void>;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [actionText, setActionText] = useState<string>('');
  const [contextText, setContextText] = useState<string>('');
  const [contentText, setContentText] = useState<string>('');

  const onSave = useCallback(() => {
    if (actionText === '' && contentText === '') {
      return;
    }
    setIsSaving(true);
    addEvent({
      actionText,
      contextText,
      contentText,
      isContentTextRich: contentText !== '',
    }).then(() => {
      setIsSaving(false);
      close();
    });
  }, [actionText, addEvent, close, contentText, contextText]);

  const draftEvent = useMemo((): Timeline.Event | undefined => {
    if (actionText === '' && contentText === '') {
      return undefined;
    }

    return {
      id: 'na',
      timelineableId: 'na',
      timelineableType: 'Metric',
      createdOn: getTimeStamp(),
      createdBy: currentUser.id,
      userId: currentUser.id,
      headlineText: {
        userDisplayName: currentUser.displayName,
        actionText,
        contextText,
      },
      contentText,
      isContentTextRich: contentText !== '',
    };
  }, [
    actionText,
    contentText,
    contextText,
    currentUser.displayName,
    currentUser.id,
  ]);

  return (
    <Form>
      <FormHeader title={'Create Custom Event'} onClose={close} />
      <FormContent>
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Label'}>Action Text</Typography.Body>
            <TextInput
              data-testid="action-input"
              value={actionText}
              onChange={(e) => {
                setActionText(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Label'}>Context Text</Typography.Body>
            <TextInput
              value={contextText}
              onChange={(e) => {
                setContextText(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Label'}>Content Text</Typography.Body>
            <ContentEditable
              data-testid={'content-input'}
              html={contentText}
              onChange={(e) => {
                setContentText(e.target.value);
              }}
              style={{
                color: Colors2.Grey['1'],
                fontSize: 12,
                height: '100%',
                minHeight: 50,
                borderRadius: 8,
                border: '1px solid #e0e0e0',
                padding: '5px',
                boxShadow:
                  '0px 3px 2px -1px rgba(0, 0, 0, 0.02), 0px 1px 1px -1px rgba(0, 0, 0, 0.04)',
              }}
              dir="auto"
            />
          </div>
        </div>
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Label'}>Preview</Typography.Body>
          </div>
          <div
            style={{
              padding: 8,
              backgroundColor: '#f7f7f7',
            }}
          >
            <div style={{ backgroundColor: 'white' }}>
              {draftEvent && <Event event={draftEvent} />}
              {!draftEvent && (
                <div
                  style={{
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography.Body type={'Annotation'}>
                    Please complete the form to see a preview
                  </Typography.Body>
                </div>
              )}
            </div>
          </div>
        </div>
        <Row spaceBetween>
          <div />
          <Button
            size={'Small'}
            type={'Primary'}
            label={'Save'}
            onClick={onSave}
            isDisabled={!draftEvent || isSaving}
            isLoading={isSaving}
          />
        </Row>
      </FormContent>
    </Form>
  );
};

const AddEventButton = () => {
  const { isOpen, open, close } = usePopup();
  const { addEvent } = useContext(LocalTimelineContext);

  if (!addEvent) {
    return null;
  }

  return (
    <>
      <div>
        <Button
          size={'Small'}
          type={'Secondary'}
          onClick={open}
          icon="add"
          label="Add Custom Event"
        />
      </div>

      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <AddEventForm close={close} addEvent={addEvent} />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default AddEventButton;
