import React, { useContext } from 'react';
import DetailsSlideOutTimeline from './DetailsSlideOutTimeline';
import OrderDetailsContext from '../../contexts/OrderDetailsContext';

const DetailsSlideOutTimelineContainer = () => {
  const { events, isLoading } = useContext(OrderDetailsContext);
  const isEmptyState = !isLoading && events.length === 0;

  return (
    <DetailsSlideOutTimeline events={events} isEmptyState={isEmptyState} />
  );
};

export default DetailsSlideOutTimelineContainer;
