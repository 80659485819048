import React from 'react';
import { GridApi, ColumnApi } from 'ag-grid-community';

interface GridContextType {
  gridId: string;
  gridApi?: GridApi;
  setGridApi: (api: GridApi) => void;
  columnApi?: ColumnApi;
  setColumnApi: (api: ColumnApi) => void;
  dataType: string;
  groups: string[];
  currentGroup?: string;
  setCurrentGroup: (g: string | undefined) => void;
  metricFiltering?: FilterInput;
  dateScopeOverride?: DateRangeInput;
  gridQueryOverride?: string;
  gridCountQueryOverride?: string;
  gridTotalsQueryOverride?: string;
  baseViewOverride?: FleetOps.BaseView;
  sortOverride?: SimpleGridSort[];
  titleOverride?: string;
  sort: SimpleGridSort[];
  setSort: React.Dispatch<React.SetStateAction<SimpleGridSort[]>>;
  restartGrid: (noFlash?: boolean) => void;
  isLoadingData: boolean;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
}
const GridContext = React.createContext<GridContextType>({
  isLoadingData: false,
  setIsLoadingData: (b: boolean) => {},
} as GridContextType);

export default GridContext;
