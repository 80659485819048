import { ApisauceInstance } from 'apisauce';

const exportGrid = ({
  api,
  name,
  data_type,
  filters,
  date_scope,
  sort_by,
  include_fields,
  totals_query,
  totals_fields,
}: {
  api: ApisauceInstance;
  name: string;
  data_type: string;
  filters: FilterInput[];
  date_scope: DateRangeInput;
  sort_by: GridSortField[];
  include_fields: { label: string; field: string }[];
  totals_query: string;
  totals_fields: GridAggregateField[];
}) =>
  api.post<{ exportDocumentId: string }>('/v1/enqueue_export', {
    name,
    data_type,
    filters,
    date_scope,
    sort_by,
    include_fields,
    totals_fields,
    totals_query,
  });

export default exportGrid;
