import { useCallback, useContext, useEffect, useState } from 'react';
import RolesContext from '../../contexts/RolesContext';
import PERMISSIONS from '../../permissionDefinitions';
import useIsDataPermissionsEnabled from './useIsDataPermissionsEnabled';
import FeatureGatesContext from '../../contexts/FeatureGatesContext';

const useGrantablePermissions = ({
  isUserShowScreen,
}: {
  isUserShowScreen: boolean;
}) => {
  const { isManualKpiPermissionManagementEnabled } =
    useContext(FeatureGatesContext);
  const { currentPermissions } = useContext(RolesContext);
  const isDataPermissionsEnabled = useIsDataPermissionsEnabled();

  const buildDataPermissions = useCallback(() => {
    if (isDataPermissionsEnabled) {
      return [
        {
          permission: PERMISSIONS.VIEW_DATA.VIEW_REVENUE,
          isEnabledByDefault: true,
        },
        {
          permission: PERMISSIONS.VIEW_DATA.VIEW_ACCIDENTS,
          isEnabledByDefault: true,
        },
        {
          permission: PERMISSIONS.VIEW_DATA.VIEW_DRIVER_PAY,
          isEnabledByDefault: true,
        },
      ];
    }
    return [];
  }, [isDataPermissionsEnabled]);
  const [dataPermissions, setDataPermissions] = useState<
    {
      permission: FleetOps.Permission;
      isEnabledByDefault: boolean;
    }[]
  >(() => buildDataPermissions());
  useEffect(() => {
    setDataPermissions(() => buildDataPermissions());
  }, [buildDataPermissions]);

  const buildConfigPermissions = useCallback(() => {
    const configPermissions: {
      permission: FleetOps.Permission;
      isEnabledByDefault: boolean;
    }[] = [];
    if (
      currentPermissions.includes(
        PERMISSIONS.USER_MANAGEMENT.GRANT_INTEGRATION_PERMISSIONS,
      )
    ) {
      configPermissions.push({
        permission: PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_VIEW,
        isEnabledByDefault: false,
      });
      configPermissions.push({
        permission: PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_MODIFY,
        isEnabledByDefault: false,
      });
    }

    if (
      currentPermissions.includes(
        PERMISSIONS.USER_MANAGEMENT.GRANT_BILLING_PERMISSIONS,
      ) ||
      isUserShowScreen
    ) {
      configPermissions.push({
        permission: PERMISSIONS.BILLING.VIEW_BILLING,
        isEnabledByDefault: false,
      });
      configPermissions.push({
        permission: PERMISSIONS.BILLING.EDIT_BILLING,
        isEnabledByDefault: false,
      });
    }

    if (isManualKpiPermissionManagementEnabled) {
      configPermissions.push({
        permission: PERMISSIONS.SCORECARDS.MANUAL_KPI_ENTRY,
        isEnabledByDefault: false,
      });
    }

    return configPermissions;
  }, [
    currentPermissions,
    isManualKpiPermissionManagementEnabled,
    isUserShowScreen,
  ]);
  const [configPermissions, setConfigPermissions] = useState<
    {
      permission: FleetOps.Permission;
      isEnabledByDefault: boolean;
    }[]
  >([]);

  useEffect(() => {
    setConfigPermissions(() => buildConfigPermissions());
  }, [buildConfigPermissions]);

  const buildAllPermissions = useCallback(() => {
    return [...dataPermissions, ...configPermissions];
  }, [configPermissions, dataPermissions]);
  const [allPermissions, setAllPermissions] = useState<
    {
      permission: FleetOps.Permission;
      isEnabledByDefault: boolean;
    }[]
  >(buildAllPermissions());
  useEffect(() => {
    setAllPermissions(buildAllPermissions());
  }, [buildAllPermissions]);

  return {
    dataPermissions,
    configPermissions,
    allPermissions,
  };
};

export default useGrantablePermissions;
