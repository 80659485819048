import React, { useContext, useEffect, useState } from 'react';
import FilterPlatesContext from '../contexts/FilterPlatesContext';
import useToFixedFilters from 'hooks/useToFixedFilters';
import VariableFiltersContext from '../contexts/VariableFiltersContext';

/**
 * This provider has two purposes:
 * 1) Dictate the filters state for a visualisation, generally consumed by {@link useFilterInput}
 * 2) A central point for our various visualisation forms to configure filtering state
 */
const FilterPlatesProvider = ({
  children,
  drillDowns,
  setDrillDowns,
  scope,
  setScope,
  dataTypes,
  dataType,
  quickFilters,
  isSavedFiltersSelectorDisabled,
  isDefaultFiltersDisabled,
  setIsDefaultFiltersDisabled,
  fieldRedList,
  textFieldModeGreenList,
}: {
  children: JSX.Element | JSX.Element[];
  drillDowns?: FilterPlateType[];
  setDrillDowns?: React.Dispatch<React.SetStateAction<FilterPlateType[]>>;
  scope?: FilterPlateType[];
  setScope?: React.Dispatch<React.SetStateAction<FilterPlateType[]>>;
  dataTypes?: string[];
  dataType?: string;
  quickFilters?: FilterInput[];
  isSavedFiltersSelectorDisabled?: boolean;
  isDefaultFiltersDisabled?: boolean;
  setIsDefaultFiltersDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  fieldRedList?: string[];
  textFieldModeGreenList?: {
    [field: string]: TextFilterMode[];
  };
}) => {
  const { variableFilters } = useContext(VariableFiltersContext);
  const [netDrillDowns, setNetDrillDowns] = useState<FixedFilter[]>([]);
  const [netScope, setNetScope] = useState<FixedFilter[]>([]);
  const [dataTypeAsArray, setDataTypeAsArray] = useState<string[] | undefined>(
    dataType ? [dataType] : undefined,
  );
  const toDrillDowns = useToFixedFilters();

  useEffect(() => {
    setDataTypeAsArray(dataType ? [dataType] : undefined);
  }, [dataType]);

  useEffect(() => {
    setNetDrillDowns(
      toDrillDowns({
        plates: drillDowns || window.emptyArray,
        variableDrillDowns: variableFilters,
      }),
    );

    setNetScope(
      toDrillDowns({
        plates: scope || window.emptyArray,
        variableDrillDowns: variableFilters,
      }),
    );
  }, [drillDowns, scope, toDrillDowns, variableFilters]);

  return (
    <FilterPlatesContext.Provider
      value={{
        drillDowns: drillDowns || window.emptyArray,
        setDrillDowns: setDrillDowns || window.tokenFunction,
        drillDownsAsFixed: netDrillDowns,
        scope: scope || window.emptyArray,
        setScope: setScope || window.tokenFunction,
        scopeAsFixed: netScope,
        dataTypes: dataTypes || dataTypeAsArray || window.emptyArray,
        quickFilters: quickFilters || window.emptyArray,
        isSavedFiltersSelectorDisabled,
        isDefaultFiltersDisabled,
        setIsDefaultFiltersDisabled,
        fieldRedList,
        textFieldModeGreenList,
      }}
    >
      {children}
    </FilterPlatesContext.Provider>
  );
};

export default FilterPlatesProvider;
