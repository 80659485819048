import React from 'react';

interface FilterPlatesContextType {
  drillDowns: FilterPlateType[];
  setDrillDowns: React.Dispatch<React.SetStateAction<FilterPlateType[]>>;
  drillDownsAsFixed: FixedFilter[];
  scope: FilterPlateType[];
  setScope: React.Dispatch<React.SetStateAction<FilterPlateType[]>>;
  scopeAsFixed: FixedFilter[];
  dataTypes?: string[];
  quickFilters: FilterInput[];
  isSavedFiltersSelectorDisabled?: boolean;
  isDefaultFiltersDisabled?: boolean;
  setIsDefaultFiltersDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  fieldRedList?: string[];
  /**
   * In some circumstances we want to declare a green list for which filter
   * modes we allow certain fields to be used with.
   *
   * For example, in a {@link UserManagement.EngagementContentSettings}, when
   * building the additional filters, we want to make sure that if the
   * primary field is used again that it is compatible with the
   * 'is one of' which the primaryFieldValue translates into
   *
   * In that case, the value of this would be something like:
   * { driverManager: ['is one of']}
   */
  textFieldModeGreenList?: {
    [field: string]: TextFilterMode[];
  };
}

const FilterPlatesContext = React.createContext<FilterPlatesContextType>({
  drillDowns: [] as FilterPlateType[],
  scope: [] as FilterPlateType[],
  quickFilters: [] as FilterInput[],
  scopeAsFixed: [] as FixedFilter[],
  drillDownsAsFixed: [] as FixedFilter[],
  fieldRedList: [] as string[],
} as FilterPlatesContextType);

export default FilterPlatesContext;
