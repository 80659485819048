import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import Colors2 from '../../theme/Colors2';

const Heading = ({ label, iconSrc }: { label: string; iconSrc: string }) => (
  <div style={{ marginBottom: 24 }}>
    <Row centerAlign style={{ height: '100%' }}>
      <img
        alt={label}
        src={iconSrc}
        style={{
          marginRight: 6,
          width: 22,
          height: 22,
          minWidth: 22,
          minHeight: 22,
        }}
      />
      <Typography.Header type={'H5'} color={Colors2.Grey['5']}>
        {label}
      </Typography.Header>
    </Row>
  </div>
);

export default Heading;
