import React, { useCallback, useContext, useEffect, useState } from 'react';

import DatasetDefinitionsContext from '../../../../contexts/DatasetDefinitionsContext';
import buildBaseView from './buildBaseView';
import BaseViewsContext from 'contexts/BaseViewsContext';
import updateBaseViewDoc from '../../../../api/updateBaseViewDoc';
import toSentenceCase from '../../../../services/toSentenceCase';
import isDefined from '../../../../isDefined';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import useBaseViewsLastUpdatedAtLookup from './useBaseViewsLastUpdatedAtLookup';
import getPersistedBaseViewsRef from './getPersistedBaseViewsRef';

export const useBaseViews = (datasets: FleetOps.DatasetDefinition[]) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { isFleetOpsStaff } = useContext(CurrentUserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [persistedBaseViews, setPersistedBaseViews] = useState<
    FleetOps.BaseView[]
  >([]);
  const [baseViews, setBaseViews] = useState<{
    [dataset: string]: FleetOps.BaseView | undefined;
  }>({});
  const [dataTypes, setDataTypes] = useState<string[]>([]);
  const [dataTypeOptions, setDataTypeOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    setDataTypeOptions(
      dataTypes
        .map((dt) => {
          const baseView = baseViews[dt];
          if (baseView && baseView.isHidden && !isFleetOpsStaff) {
            return undefined;
          }

          if (baseView && baseView.nameAlias) {
            return {
              label: baseView.nameAlias,
              value: dt,
            };
          }

          return {
            label: toSentenceCase(dt),
            value: dt,
          };
        })
        .filter(isDefined),
    );
  }, [baseViews, dataTypes, isFleetOpsStaff]);

  const getBaseViews = useCallback(() => {
    if (accountRef) {
      getPersistedBaseViewsRef(accountRef)
        .get()
        .then((snapshot) => {
          const docs = snapshot.docs;
          const newViews = [] as FleetOps.BaseView[];
          docs.forEach((d) => {
            newViews.push(d.data() as FleetOps.BaseView);
          });
          setPersistedBaseViews(newViews);
        });
    }
  }, [accountRef]);

  useEffect(() => {
    getBaseViews();
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const refreshInterval = setInterval(getBaseViews, 1000 * 60 * 60 * 24);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [accountRef, getBaseViews]);

  const getBaseView = useCallback(
    async (dataset: FleetOps.DatasetDefinition): Promise<FleetOps.BaseView> => {
      const persistedBaseView = persistedBaseViews.find(
        (v) => v.type === dataset.type,
      );

      return buildBaseView({
        persistedBaseView,
        dataset,
      });
    },
    [persistedBaseViews],
  );

  const updateBaseView = useCallback(
    (newBaseView: FleetOps.BaseView) => {
      updateBaseViewDoc(newBaseView, accountRef);
      setBaseViews((currentViews) => {
        return {
          ...currentViews,
          [newBaseView.type]: newBaseView,
        };
      });
    },
    [accountRef],
  );

  useEffect(() => {
    let isActive = true;
    const buildTasks = datasets.map((d) => getBaseView(d));
    Promise.all(buildTasks).then((views) => {
      if (!isActive) {
        return;
      }
      const newViews = {} as {
        [dataset: string]: FleetOps.BaseView | undefined;
      };
      views.forEach((view) => {
        newViews[view.type] = view;
      });
      setBaseViews(newViews);
      setDataTypes(datasets.map((d) => d.type));
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [accountRef, datasets, getBaseView]);

  const getDatasetLabel = useCallback(
    (dataset: string) => {
      const bv = baseViews[dataset];
      if (!bv) {
        return toSentenceCase(dataset);
      }

      return bv.nameAlias ? bv.nameAlias : toSentenceCase(dataset);
    },
    [baseViews],
  );

  /**
   * The client will generate a default base view for each dataset if one is
   * not persisted already. The client will persist a {@link FleetOps.BaseView} when a change
   * is made to it within the data manager
   *
   * This {@link FleetOps.BaseView} is a dependency within the export
   * service, so let's ensure it's written into firestore before requesting
   * a grid export.
   */
  const ensureBaseViewIsPersisted = useCallback(
    async (baseView: FleetOps.BaseView) => {
      const isPersisted = persistedBaseViews.some(
        (v) => v.type === baseView.type,
      );
      if (isPersisted) {
        return;
      }

      updateBaseView(baseView);
    },
    [persistedBaseViews, updateBaseView],
  );

  return {
    isLoading,
    baseViews,
    dataTypes,
    updateBaseView,
    dataTypeOptions,
    getDatasetLabel,
    ensureBaseViewIsPersisted,
  };
};

const BaseViewsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const {
    isLoading,
    baseViews,
    dataTypes,
    updateBaseView,
    dataTypeOptions,
    getDatasetLabel,
    ensureBaseViewIsPersisted,
  } = useBaseViews(datasets);
  const baseViewsLastUpdatedAtLookup =
    useBaseViewsLastUpdatedAtLookup(datasets);

  return (
    <BaseViewsContext.Provider
      value={{
        baseViews,
        baseViewsLastUpdatedAtLookup,
        isLoading,
        dataTypes,
        updateBaseView,
        dataTypeOptions,
        getDatasetLabel,
        ensureBaseViewIsPersisted,
      }}
    >
      {children}
    </BaseViewsContext.Provider>
  );
};

export default BaseViewsProvider;
