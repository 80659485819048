import React, { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';

import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import Loading from '../../../../components/Loading';
import Flash from '../../../../components/Flash';
import useUserAccounts from './useUserAccounts';
import { AUTH_ERROR_MSG_KEY } from '../../../../screens/Login/ErrorBanner';
import STORE from '../../../../store';
import useHasExtraInfo from './useHasExtraInfo';

const useUrlParamAccount = () =>
  new URLSearchParams(useLocation().search).get('accountId');

const SELECTED_ACCOUNT_ID_KEY = 'selected-account';

export const clearCachedSelectedAccountId = () => {
  window.localStorage.removeItem(SELECTED_ACCOUNT_ID_KEY);
};

const useCachedSelectedAccount = (
  selectedAccount: FleetOps.UserAccountAccess | undefined,
) => {
  const urlAccountId = useUrlParamAccount();
  const [storageKey] = useState<string>(SELECTED_ACCOUNT_ID_KEY);
  const [cachedAccountId] = useState<string | undefined>(
    (() => {
      const cached = window.localStorage.getItem(storageKey);
      if (cached === null) {
        return undefined;
      }

      return cached;
    })(),
  );

  const updateCachedAccountId = useCallback(
    (newId: string) => {
      window.localStorage.setItem(storageKey, newId);
    },
    [storageKey],
  );

  const onVisibilityChanged = useCallback(() => {
    if (document.visibilityState === 'visible' && !!selectedAccount) {
      updateCachedAccountId(selectedAccount.accountId);
    }
  }, [selectedAccount, updateCachedAccountId]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChanged);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChanged);
    };
  }, [onVisibilityChanged]);

  return {
    cachedAccountId: urlAccountId ? urlAccountId : cachedAccountId,
    updateCachedAccountId,
  };
};

const AccountPickerProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { accounts, isLoading, refreshUserAccounts } = useUserAccounts();
  const [selectedAccount, setSelectedAccount] = useState<
    FleetOps.UserAccountAccess | undefined
  >();
  const { cachedAccountId, updateCachedAccountId } =
    useCachedSelectedAccount(selectedAccount);
  const [accountRef, setAccountRef] =
    useState<firebase.firestore.DocumentReference>();
  const { hasExtraInfo, onHasExtraInfoChanged } = useHasExtraInfo();

  const [isSelectedAccountMissingClaim, setIsSelectedAccountMissingClaim] =
    useState<boolean>(false);

  useEffect(() => {
    setSelectedAccount((currentlySelected) => {
      if (currentlySelected) {
        if (accounts.some((a) => a.accountId === currentlySelected.accountId)) {
          return currentlySelected;
        }
      }
      if (accounts.length === 0) {
        return undefined;
      }

      if (cachedAccountId) {
        const match = accounts.find((a) => a.accountId === cachedAccountId);
        if (match) {
          return match;
        }
      }
      return accounts[0];
    });
  }, [accounts, cachedAccountId]);

  useEffect(() => {
    if (!selectedAccount) {
      setAccountRef(undefined);
      return;
    }

    const newRef = STORE.getAccountRef({
      accountId: selectedAccount.accountId,
    });
    setAccountRef(newRef);
    updateCachedAccountId(selectedAccount.accountId);
    return () => {
      setAccountRef(undefined);
    };
  }, [selectedAccount, updateCachedAccountId]);

  useEffect(() => {
    if (!selectedAccount) {
      return;
    }
    Sentry.configureScope((scope) => {
      scope.setExtra('Account id', selectedAccount.accountId);
      scope.setExtra('Roles', selectedAccount.roles);
      scope.setExtra('Permissions', selectedAccount.permissions);
    });
  }, [selectedAccount]);

  useEffect(() => {
    if (isLoading || accounts.length > 0) {
      return;
    }

    const i = setInterval(refreshUserAccounts, 3000);
    return () => {
      clearInterval(i);
    };
  }, [accounts.length, isLoading, refreshUserAccounts]);

  const onNoAccountAccess = useCallback(async () => {
    window.localStorage.setItem(
      AUTH_ERROR_MSG_KEY,
      'You do not have access to FleetOps. Contact your IT admin',
    );
    await firebase.auth().signOut();
  }, []);

  useEffect(() => {
    if (isLoading || accounts.length > 0) {
      return;
    }

    // Wrap this in a timeout to protect against the risk of a future
    // race condition
    const to = setTimeout(onNoAccountAccess, 1000);
    return () => {
      clearTimeout(to);
    };
  }, [accounts.length, isLoading, onNoAccountAccess]);

  if (isLoading || !selectedAccount || !accountRef) {
    return <Loading />;
  }

  return (
    <AccountPickerContext.Provider
      value={{
        accounts,
        isLoading,
        selectedAccountId: selectedAccount.accountId,
        selectedAccount,
        setSelectedAccount,
        hasExtraInfo,
        onHasExtraInfoChanged,
        accountRef,
      }}
    >
      <Flash
        isFlashVisible={isSelectedAccountMissingClaim}
        setIsFlashVisible={setIsSelectedAccountMissingClaim}
        type="info"
        message="Your access to this account has been updated. We recommend signing out and logging in again for a smooth experience."
        isPermanent
      />
      {children}
    </AccountPickerContext.Provider>
  );
};

export default AccountPickerProvider;
