import React from 'react';
import SummaryTable from './SummaryTable';

const Summary = () => (
  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
    <SummaryTable />
  </div>
);

export default Summary;
