import { useCallback, useContext, useState } from 'react';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';

const useOnSave = ({
  getNewDefinition,
  isValid,
  close,
}: {
  getNewDefinition: () => EntityDetails.Entity | undefined;
  isValid: boolean;
  close: () => void;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onSave = useCallback(async () => {
    if (!isValid || isLoading) {
      return;
    }

    const newDef = getNewDefinition();
    if (!newDef) {
      return;
    }

    setIsLoading(true);

    await STORE.getEntityDefinitionsRef({
      accountId: selectedAccountId,
    })
      .doc(newDef.id)
      .set(newDef);

    setIsLoading(false);
    close();
  }, [close, getNewDefinition, isLoading, isValid, selectedAccountId]);

  return { onSave, isLoading };
};

export default useOnSave;
