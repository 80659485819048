import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Parser } from 'expr-eval';

import CompoundMetricForm from './CompoundMetricForm';
import CurrentUserContext from '../../../../../../contexts/CurrentUserContext';
import MetricOptionsContext from '../../../../../../contexts/MetricOptionsContext';
import AnalyticsContext from '../../../../../../contexts/AnalyticsContext';
import InSituMetricEditorContext from 'contexts/InSituMetricEditorContext';
import SingleUseMetricPopupContext from 'contexts/SingleUseMetricPopupContext';
import getTimeStamp from '../../../../../../getTimeStamp';
import ReactPortal from '../../../../../../components/ReactPortal';
import MetricPopupConstants from '../../constants';
import Preview from '../../Preview';
import MetricActions from '../../MetricActions';
import withoutNulls from '../../../../../../api/search/withoutNulls';
import CoreToggle from '../CoreToggle';
import useEditingState from '../../MetricActions/useEditingState';
import isDefined from '../../../../../../isDefined';
import metricTypeCheckers from '../../../../../../types/metricTypeCheckers';
import CompoundMetricDuplicationWarning from './CompoundMetricDuplicationWarning';

const useUsedMetrics = (metricIds: string[]) => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const [usedMetrics, setUsedMetrics] = useState<Metrics.NormalMetric[]>([]);

  useEffect(() => {
    setUsedMetrics(
      metricIds
        .map((mid) => metricOptionsLookup[mid])
        .filter(isDefined)
        .filter(metricTypeCheckers.isNormalMetric),
    );
  }, [metricIds, metricOptionsLookup]);

  return usedMetrics;
};

const CompoundMetricFormContainer = ({
  id,
  selectedMetric,
  showFlash,
  setHasUnsavedChanges,
}: {
  id: string;
  selectedMetric?: Metrics.CompoundMetric;
  showFlash: (message: string) => void;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isInSituEditor } = useContext(InSituMetricEditorContext);
  const { isSingleUsePopup } = useContext(SingleUseMetricPopupContext);
  const currentUser = useContext(CurrentUserContext);
  const { normalMetrics } = useContext(MetricOptionsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [name, setName] = useState<string>(
    selectedMetric ? selectedMetric.name : '',
  );
  const [description, setDescription] = useState<string>(
    selectedMetric && selectedMetric.description
      ? selectedMetric.description
      : '',
  );
  const [status, setStatus] = useState<Metrics.MetricStatus>(
    selectedMetric ? selectedMetric.status : 'public',
  );
  const [selectedMetricIds, setSelectedMetricIds] = useState<string[]>(
    selectedMetric ? selectedMetric.metricIds : [],
  );
  const usedMetrics = useUsedMetrics(selectedMetricIds);
  const [expression, setExpression] = useState(
    selectedMetric ? selectedMetric.expression : '',
  );
  const [formatting, setFormatting] = useState(
    selectedMetric ? selectedMetric.formatting : {},
  );
  const [isExpressionValid, setIsExpressionValid] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(
    selectedMetricIds.length > 0 && expression !== '' && isExpressionValid,
  );
  const [metricDraft, setMetricDraft] = useState<
    Metrics.CompoundMetric | undefined
  >();

  const editingState = useEditingState({ selectedMetric, metricDraft });
  useEffect(() => {
    if (selectedMetric) {
      setHasUnsavedChanges(
        editingState !== 'no changes' &&
          editingState !== 'no changes - archived',
      );
      return;
    }

    setHasUnsavedChanges(
      name !== '' ||
        description !== '' ||
        selectedMetricIds.length > 0 ||
        expression !== '',
    );
  }, [
    description,
    editingState,
    expression,
    name,
    selectedMetric,
    selectedMetricIds.length,
    setHasUnsavedChanges,
  ]);

  useEffect(() => {
    const newIsExpressionValid = (() => {
      // Try to run the expression. If it crashes, it is invalid.
      try {
        let expressionWithValues = expression.toString();
        selectedMetricIds.forEach((mId) => {
          const usageCount = expressionWithValues.split(mId).length - 1;
          for (let i = 0; i < usageCount; i++) {
            expressionWithValues = expressionWithValues.replace(mId, '1');
          }
        });
        const parser = new Parser();
        parser.functions.annualized = function (metricValue: number) {
          return metricValue;
        };
        parser.evaluate(expressionWithValues);
        return true;
      } catch (ex) {
        return false;
      }
    })();
    setIsExpressionValid(newIsExpressionValid);
  }, [expression, selectedMetricIds]);

  useEffect(() => {
    const newIsValid =
      selectedMetricIds.length > 0 && expression !== '' && isExpressionValid;

    setIsValid(newIsValid);
  }, [expression, isExpressionValid, selectedMetricIds.length]);

  useEffect(() => {
    if (!isValid) {
      setMetricDraft(undefined);
      return;
    }

    const newMetricDraft: Metrics.CompoundMetric = {
      id,
      name,
      description,
      status,
      type: 'compound',
      formatting,
      metricIds: selectedMetricIds,
      expression,
      createdBy: selectedMetric ? selectedMetric.createdBy : currentUser.id,
      createdOn: selectedMetric ? selectedMetric.createdOn : getTimeStamp(),
      updatedBy: currentUser.id,
      updatedOn: getTimeStamp(),
    };

    setMetricDraft(withoutNulls(newMetricDraft));
  }, [
    currentUser.id,
    description,
    status,
    expression,
    formatting,
    id,
    isValid,
    name,
    selectedMetric,
    selectedMetricIds,
  ]);

  const onNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const onDescriptionChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
    },
    [],
  );

  const onExpressionChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setExpression(event.target.value);
    },
    [],
  );

  const onAddMetric = useCallback(
    (id: string) => {
      setSelectedMetricIds([...selectedMetricIds, id]);
      trackEvent('Compound Metric Builder - Metric added');
    },
    [selectedMetricIds, trackEvent],
  );

  const onRemoveMetric = useCallback(
    (id: string) => {
      setSelectedMetricIds(selectedMetricIds.filter((mId) => mId !== id));
      trackEvent('Compound Metric Builder - Metric removed');
    },
    [selectedMetricIds, trackEvent],
  );

  const onMetricPickerOpened = useCallback(() => {
    trackEvent('Compound Metric Builder - Add metric clicked');
  }, [trackEvent]);

  return (
    <>
      <CompoundMetricForm
        name={name}
        onNameChanged={onNameChanged}
        description={description}
        onDescriptionChanged={onDescriptionChanged}
        formatting={formatting}
        setFormatting={setFormatting}
        normalMetrics={normalMetrics}
        selectedMetricIds={selectedMetricIds}
        selectedMetrics={usedMetrics}
        onAddMetric={onAddMetric}
        onRemoveMetric={onRemoveMetric}
        expression={expression}
        onExpressionChanged={onExpressionChanged}
        onMetricPickerOpened={onMetricPickerOpened}
      />
      {isValid && !!metricDraft && (
        <ReactPortal
          elementId={
            isInSituEditor
              ? MetricPopupConstants.PREVIEW_IN_SITU_DIV_ID
              : isSingleUsePopup
                ? MetricPopupConstants.PREVIEW_SINGLE_USE_DIV_ID
                : MetricPopupConstants.PREVIEW_DIV_ID
          }
        >
          <Preview metricDraft={metricDraft} />
          <CompoundMetricDuplicationWarning metricDraft={metricDraft} />
        </ReactPortal>
      )}
      <ReactPortal
        elementId={
          isInSituEditor
            ? MetricPopupConstants.PRIMARY_ACTION_IN_SITU_DIV_ID
            : isSingleUsePopup
              ? MetricPopupConstants.PRIMARY_ACTION_SINGLE_USE_DIV_ID
              : MetricPopupConstants.PRIMARY_ACTION_DIV_ID
        }
      >
        <MetricActions
          metricDraft={metricDraft}
          selectedMetric={selectedMetric}
          setStatus={setStatus}
          showFlash={showFlash}
        />
      </ReactPortal>
      <ReactPortal
        elementId={
          isInSituEditor
            ? MetricPopupConstants.CORE_TOGGLE_IN_SITU_DIV_ID
            : isSingleUsePopup
              ? MetricPopupConstants.CORE_TOGGLE_SINGLE_USE_DIV_ID
              : MetricPopupConstants.CORE_TOGGLE_DIV_ID
        }
      >
        <CoreToggle
          status={status}
          setStatus={setStatus}
          selectedMetric={selectedMetric}
        />
      </ReactPortal>
    </>
  );
};

export default CompoundMetricFormContainer;
