import React from 'react';
import OrderDetails from './OrderDetails';
import OrderDetailsProvider from '../../contextProviders/OrderDetailsProvider';
import { ScreenWrapper } from '../../navigation/styles';
import { useNavigate, useParams } from 'react-router-dom';
import AppRoutes from '../../navigation/appRoutes';

const OrderDetailsScreenContainer = () => {
  const navigate = useNavigate();
  const { orderNumber } = useParams<{
    orderNumber: string;
  }>();
  if (!orderNumber) {
    navigate(AppRoutes.home);
    return null;
  }

  return (
    <ScreenWrapper>
      <OrderDetailsProvider orderNumber={orderNumber}>
        <OrderDetails />
      </OrderDetailsProvider>
    </ScreenWrapper>
  );
};

export default OrderDetailsScreenContainer;
