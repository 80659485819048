import React, { useContext } from 'react';
import OrderDetailsContext from '../../../contexts/OrderDetailsContext';
import GridProvider from '../../../contextProviders/GridProvider';
import Grid from '../../Grid';

const OrderTabContainer = ({ tab }: { tab: string }) => {
  const { orderNumber } = useContext(OrderDetailsContext);

  return (
    <GridProvider
      dataType={tab.toLowerCase()}
      metricFiltering={{
        keywords: [{ field: 'orderNo', values: [orderNumber] }],
        ranges: [],
        booleanFilters: [],
        wildcardFilters: [],
      }}
    >
      <Grid
        layoutOnFirstRender={false}
        layoutOnColumnChange={false}
        layoutOnModelUpdated={true}
        hasBorders
        hasDarkHeader
      />
    </GridProvider>
  );
};

export default OrderTabContainer;
