import React, { useContext } from 'react';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { buildShowOrderDetails } from '../../navigation/appRoutes';
import Row from '../Common/Row';
import Button from '../../kingpin/atoms/Button';
import Colors2 from '../../theme/Colors2';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colors2.Border};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
`;

const DetailsSlideOutHeading = ({
  orderNumber,
  close,
}: {
  orderNumber: string;
  close: () => void;
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  return (
    <Wrapper>
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type={'H5'}>
          {`Order #${orderNumber}`}
        </Typography.Header>
      </div>
      <Row>
        <Link
          to={buildShowOrderDetails(orderNumber, selectedPortal)}
          target={'_blank'}
          style={{ marginRight: 8 }}
        >
          <Button size={'Small'} type={'Secondary'} icon="open-link" />
        </Link>
        <Button
          size={'Small'}
          type={'Secondary'}
          icon="cross"
          onClick={close}
        />
      </Row>
    </Wrapper>
  );
};

export default DetailsSlideOutHeading;
