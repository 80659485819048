import React, { useContext } from 'react';
import OrderDetailsTopBar from './OrderDetailsTopBar';
import OrderDetailsContext from '../../contexts/OrderDetailsContext';
import moment from 'moment';

const format = (dateTime: string, isPlaned: boolean) => {
  const formattedDate = moment.utc(dateTime).format('hh:mma, MMM Do');
  return `${formattedDate}${isPlaned ? ' (Planned)' : ''}`;
};

const OrderDetailsTopBarContainer = () => {
  const { orderNumber, orderSummary } = useContext(OrderDetailsContext);

  const dateRange = (() => {
    try {
      if (!orderSummary) {
        return '';
      }
      const { started, finished, plannedStarted, plannedFinished } =
        orderSummary;
      return `${format(started || plannedStarted, !started)} - ${format(
        finished || plannedFinished,
        !finished,
      )}`;
    } catch (ex) {
      return '';
    }
  })();

  return <OrderDetailsTopBar orderNumber={orderNumber} dateRange={dateRange} />;
};

export default OrderDetailsTopBarContainer;
