import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const NORMAL_METRICS_DOC = 'normalMetrics';
export const CURRENT_NORMAL_METRIC_VERSION = 'v1';

const normalMetricConverter = {
  toFirestore(metric: Metrics.NormalMetric): firebase.firestore.DocumentData {
    return withoutNulls({ ...metric });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<Metrics.NormalMetric>,
    options: firebase.firestore.SnapshotOptions,
  ): Metrics.NormalMetric {
    const data = snapshot.data(options);
    if (data.datasetFilterIds === undefined) {
      data.datasetFilterIds = [];
    }
    return data;
  },
};

const getNormalMetricsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.METRIC_DEFINITIONS_COLLECTION)
    .doc(NORMAL_METRICS_DOC)
    .collection(CURRENT_NORMAL_METRIC_VERSION)
    .withConverter(normalMetricConverter);
};

export default getNormalMetricsRef;
