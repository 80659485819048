import React, { useContext } from 'react';
import NewCommitPopup from './NewCommitPopup';
import { LAST_180_DAYS, LAST_3_YEARS_TO_DATE } from '../../DateInput/constants';
import DateInputProvider from '../../../contextProviders/DateInputProvider';
import AccountContext from '../../../contexts/AccountContext';

const NewCommitPopupContainer = () => {
  const { isDemoAccount } = useContext(AccountContext);
  return (
    <DateInputProvider
      initialRelativeDateRange={
        isDemoAccount ? LAST_3_YEARS_TO_DATE : LAST_180_DAYS
      }
    >
      <NewCommitPopup />
    </DateInputProvider>
  );
};

export default NewCommitPopupContainer;
