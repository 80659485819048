import React, { ChangeEvent } from 'react';
import Inputs from 'components/Inputs';
import Row from '../../Common/Row';
import Button from 'kingpin/atoms/Button';
import Form from '../../../kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';
import EntityFilterToggle from '../../EntityFilterToggle';

const SettingsPopup = ({
  name,
  onNameChanged,
  isEntityFilterEnabled,
  setIsEntityFilterEnabled,
  onSave,
  isLoading,
  close,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  isEntityFilterEnabled: boolean;
  setIsEntityFilterEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => void;
  isLoading: boolean;
  close: () => void;
}) => (
  <Form>
    <FormHeader title="Rename board" onClose={close} />
    <FormContent>
      <Inputs.TextInput
        value={name}
        onChange={onNameChanged}
        label="Name"
        inputSize="Small"
      />
    </FormContent>
    <FormContent>
      <EntityFilterToggle
        isEntityFilterEnabled={isEntityFilterEnabled}
        setIsEntityFilterEnabled={setIsEntityFilterEnabled}
      />
    </FormContent>
    <Row style={{ justifyContent: 'flex-end', marginBottom: 16 }}>
      <Button
        type="Primary"
        size="Small"
        onClick={onSave}
        isLoading={isLoading}
        isDisabled={isLoading}
        label="Save"
      />
    </Row>
  </Form>
);

export default SettingsPopup;
