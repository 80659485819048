const engagementPortalRoutes = {
  home: '/:portal',
  reportsIndex: '/:portal/reports',
  reportShow: '/:portal/reports/:reportId',
  dashboardsIndex: '/:portal/dashboards',
  dashboardShow: '/:portal/dashboards/:dashboardId',
  scorecardsIndex: '/:portal/kpi-lists',
  scorecardShow: '/:portal/kpi-lists/:scorecardId',
  targetManagerShow: '/:portal/targets/:dataset',
  entityShow: '/:portal/entity/:entityAppId',
};

export const engagementPortalRouteBuilders = (portalSlug: string) => ({
  home: `${portalSlug}`,
  reportsIndex: `/${portalSlug}/reports`,
  dashboardsIndex: `/${portalSlug}/dashboards`,
  scorecardsIndex: `/${portalSlug}/kpi-lists`,
  targetManagerShow: (dataset: string) => `/${portalSlug}/targets/${dataset}`,
  users: (portal: EngagementPortal) => {
    if (portal.entityLinks.length > 0) {
      return `/${portalSlug}/access/users/${portal.entityLinks[0].entityId}`;
    }
    return `/${portalSlug}/access/users/managers`;
  },
});

export default engagementPortalRoutes;
