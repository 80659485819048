import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import TimelineEvent from '../OrderDetailsTimeline/TimelineEvent';
import Card from '../Common/Card';
import FlexCentered from '../Common/FlexCentered';

const DetailsSlideOutTimeline = ({
  events,
  isEmptyState,
}: {
  events: TimelineEvent[];
  isEmptyState: boolean;
}) => (
  <div style={{ marginBottom: 24 }}>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type={'H5'}>Timeline</Typography.Header>
    </div>
    <div>
      {isEmptyState && (
        <Card style={{ marginBottom: 16 }}>
          <FlexCentered style={{ height: 200 }}>
            <Typography.Header type={'H3'}>
              Timeline not found
            </Typography.Header>
          </FlexCentered>
        </Card>
      )}
      {!isEmptyState && (
        <>
          {events.map((e) => (
            <TimelineEvent key={e.clientId} timelineEvent={e} />
          ))}
        </>
      )}
    </div>
  </div>
);

export default DetailsSlideOutTimeline;
