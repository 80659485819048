import { useCallback, useContext } from 'react';
import DashboardsContext from 'contexts/DashboardsContext';
import DashboardGadgetsContext from 'contexts/DashboardGadgetsContext';
import GoalsContext from 'contexts/GoalsContext';
import MetricListsContext from 'contexts/MetricListsContext';
import ScorecardsContext from 'contexts/ScorecardsContext';
import WorkSpacesContext from 'contexts/WorkSpacesContext';
import kpiTypeCheckers from 'hooks/kpiTypeCheckers';
import cardTypeCheckers from 'types/cardTypeCheckers';
import EntityDefinitionsContext from '../../../../../contexts/EntityDefinitionsContext';
import _ from 'lodash';

const useGetBoardUsageReport = () => {
  const { allDashboards } = useContext(DashboardsContext);
  const { allGadgets } = useContext(DashboardGadgetsContext);
  const { metricLists } = useContext(MetricListsContext);
  const { scorecards } = useContext(ScorecardsContext);
  const { goals } = useContext(GoalsContext);
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const { entityDefinitions, entityDetailsApps } = useContext(
    EntityDefinitionsContext,
  );

  const isInScorecard = useCallback(
    ({
      scorecard,
      boardId,
    }: {
      scorecard: Scorecards.Scorecard;
      boardId: string;
    }): boolean => {
      return scorecard.kpis
        .filter(kpiTypeCheckers.isNotEmptyRow)
        .some((k) => k.boardDrillDownId === boardId);
    },
    [],
  );

  const isInGoal = useCallback(
    ({ goal, boardId }: { goal: GeneralGoal; boardId: string }): boolean => {
      return goal.kpis.some((kpi) => kpi.boardDrillDownId === boardId);
    },
    [],
  );

  const isInDashboard = useCallback(
    ({ dashboard, boardId }: { dashboard: DashboardType; boardId: string }) => {
      return dashboard.canvas.cards.some((c) => {
        if (cardTypeCheckers.isDashboardGadget(c)) {
          const gadget = allGadgets.find(
            (g) => g.id === c.content.dashboardGadgetId,
          );
          return gadget && gadget.boardDrillDownId === boardId;
        }

        if (cardTypeCheckers.isMetricList(c)) {
          const metricList = metricLists.find(
            (ml) => ml.id === c.content.metricListId,
          );
          if (!metricList) {
            return false;
          }

          return metricList.list.some((item) => {
            return item.boardDrillDownId === boardId;
          });
        }

        return false;
      });
    },
    [allGadgets, metricLists],
  );

  const isInWorkspace = useCallback(
    ({
      workspace,
      boardId,
    }: {
      workspace: WorkSpace | TargetsApp.App;
      boardId: string;
    }): boolean => {
      return workspace.tabs.some((t) => {
        if (t.type === 'scorecards') {
          const scorecard = scorecards.find((s) => s.id === t.typeId);
          return scorecard && isInScorecard({ scorecard, boardId });
        } else if (t.type === 'board') {
          return t.typeId === boardId;
        } else if (t.type === 'dashboard') {
          const dashboard = allDashboards.find((d) => d.id === t.typeId);
          return dashboard && isInDashboard({ boardId, dashboard });
        } else if (t.type === 'goals') {
          const goal = goals.find((g) => g.id === t.typeId);
          return goal && isInGoal({ goal, boardId });
        }

        return false;
      });
    },
    [allDashboards, goals, isInDashboard, isInGoal, isInScorecard, scorecards],
  );

  const getDashboardContainingGadget = useCallback(
    ({ gadgetId }: { gadgetId: string }): DashboardType | undefined => {
      return allDashboards.find((d) => {
        return d.canvas.cards.some((c) => {
          if (cardTypeCheckers.isDashboardGadget(c)) {
            return c.content.dashboardGadgetId === gadgetId;
          }
          return false;
        });
      });
    },
    [allDashboards],
  );

  const getDashboardContainingMetricList = useCallback(
    ({ metricListId }: { metricListId: string }): DashboardType | undefined => {
      return allDashboards.find((d) => {
        return d.canvas.cards.some((c) => {
          if (cardTypeCheckers.isMetricList(c)) {
            return c.content.metricListId === metricListId;
          }
          return false;
        });
      });
    },
    [allDashboards],
  );

  const getUsedDashboardGadgets = useCallback(
    ({ boardId }: { boardId: string }): FleetOps.DashboardGadgetUsage[] => {
      const dashboardGadgetUsages: FleetOps.DashboardGadgetUsage[] = [];
      allGadgets.forEach((gadget) => {
        if (gadget.boardDrillDownId && gadget.boardDrillDownId === boardId) {
          const dashboard = getDashboardContainingGadget({
            gadgetId: gadget.id,
          });
          if (dashboard) {
            dashboardGadgetUsages.push({
              dashboardId: dashboard.id,
              dashboardName: dashboard.name,
              gadgetType: 'chartDef',
              gadgetId: gadget.id,
              gadgetName: gadget.chartDef.name,
              id: `${gadget.id}-${dashboard.id}`,
            });
          }
        }
      });

      metricLists.forEach((metricList) => {
        metricList.list.forEach((metricListItem) => {
          if (
            metricListItem.boardDrillDownId &&
            metricListItem.boardDrillDownId === boardId
          ) {
            const dashboard = getDashboardContainingMetricList({
              metricListId: metricList.id,
            });
            if (dashboard) {
              dashboardGadgetUsages.push({
                dashboardId: dashboard.id,
                dashboardName: dashboard.name,
                gadgetType: 'metricList',
                gadgetId: metricList.id,
                gadgetName: metricList.name,
                id: `${metricList.id}-${dashboard.id}`,
              });
            }
          }
        });
      });

      return _.uniqBy(dashboardGadgetUsages, 'id');
    },
    [
      allGadgets,
      getDashboardContainingGadget,
      getDashboardContainingMetricList,
      metricLists,
    ],
  );

  const getUsedScorecards = useCallback(
    ({ boardId }: { boardId: string }): Scorecards.Scorecard[] => {
      return scorecards.filter((scorecard) =>
        isInScorecard({ scorecard, boardId }),
      );
    },
    [isInScorecard, scorecards],
  );

  const getUsedGoals = useCallback(
    ({ boardId }: { boardId: string }): GeneralGoal[] => {
      return goals.filter((goal) => isInGoal({ goal, boardId }));
    },
    [goals, isInGoal],
  );

  const getUsedWorkspaces = useCallback(
    ({ boardId }: { boardId: string }): (WorkSpace | TargetsApp.App)[] => {
      return allWorkSpaces.filter((workspace) =>
        isInWorkspace({ workspace, boardId }),
      );
    },
    [allWorkSpaces, isInWorkspace],
  );

  const getUsedEntities = useCallback(
    ({ boardId }: { boardId: string }) => {
      return entityDefinitions.filter((entityDefinition) => {
        const app = entityDetailsApps.find(
          (a) => entityDefinition.id === a.entityId,
        );
        if (!app) {
          return false;
        }

        return app.tabs.some((tab) => {
          if (tab.type === 'dashboard') {
            const dashboard = allDashboards.find((d) => d.id === tab.typeId);

            return dashboard && isInDashboard({ dashboard, boardId });
          }
          if (tab.type === 'board') {
            return tab.typeId === boardId;
          }
          return false;
        });
      });
    },
    [allDashboards, entityDefinitions, entityDetailsApps, isInDashboard],
  );

  const getBoardUsageReport = useCallback(
    (boardId: string): FleetOps.BoardUsageReport => {
      const dashboardGadgets = getUsedDashboardGadgets({ boardId });
      const scorecards = getUsedScorecards({ boardId });
      const goals = getUsedGoals({ boardId });
      const workSpaces = getUsedWorkspaces({ boardId });
      const entities = getUsedEntities({ boardId });

      const usageCount =
        dashboardGadgets.length +
        scorecards.length +
        goals.length +
        workSpaces.length +
        entities.length;

      return {
        dashboardGadgets,
        scorecards,
        goals,
        workSpaces,
        usageCount,
        entities,
      };
    },
    [
      getUsedDashboardGadgets,
      getUsedEntities,
      getUsedGoals,
      getUsedScorecards,
      getUsedWorkspaces,
    ],
  );

  return getBoardUsageReport;
};

export default useGetBoardUsageReport;
