import styled from 'styled-components';
import Row from '../../../../../../components/Common/Row';
import WarningSvg from '../icons/warning.svg';
import Typography from '../../../../../../kingpin/atoms/Typography';
import React from 'react';

const ErrorContainer = styled.div`
  background-color: #ffe3e3;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
  height: fit-content;
  overflow: hidden;
  transition: height 0.2s ease-out;
`;

const ErrorSection = ({
  errors,
  errorMessage,
}: {
  errors: string[];
  errorMessage?: string;
}) => {
  if (errors.length === 0 && !errorMessage) {
    return <></>;
  }
  return (
    <ErrorContainer>
      <Row>
        <img src={WarningSvg} style={{ marginRight: '8px' }} alt="warning" />
        <Typography.Header type="H5">{errorMessage}</Typography.Header>
      </Row>
      {errors.length > 0 && (
        <>
          {errors.map((e) => (
            <Row style={{ paddingTop: '12px' }}>
              <Typography.Body type="Body 12">{e}</Typography.Body>
            </Row>
          ))}
        </>
      )}
    </ErrorContainer>
  );
};

export default ErrorSection;
