import useQueryParams from './useQueryParams';
import { useContext, useMemo, useState } from 'react';
import { CurrentUserContentSettingsContext } from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/CurrentUserContentSettingsProvider';

const useIsEngagementMode = () => {
  const { contentSettings } = useContext(CurrentUserContentSettingsContext);

  const urlParams = useQueryParams();
  const [isSimpleViewDemo] = useState<boolean>(() => urlParams.has('d'));

  const isEngagementMode = useMemo(() => {
    return contentSettings.mode === 'engagement';
  }, [contentSettings.mode]);

  const isMultipleMappings = useMemo(() => {
    if (contentSettings.mode === 'normal') {
      return false;
    }

    return contentSettings.mappings.length > 1;
  }, [contentSettings]);

  return {
    isEngagementMode: isEngagementMode || isSimpleViewDemo,
    isMultipleMappings,
  };
};

export default useIsEngagementMode;
