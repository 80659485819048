import React, { ChangeEvent, useContext, useState } from 'react';
import SettingsPopup from './SettingsPopup';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import BoardContext from '../../../contexts/BoardContext';
import updateBoard from '../../../api/boards/updateBoard';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import isCustomerLaneBoard from '../../../isCustomerLaneBoard';

const SettingsPopupContainer = ({
  close,
  board,
}: {
  close: () => void;
  board: CustomerLaneBoard;
}) => {
  const { accountRef } = useContext(AccountPickerContext);

  const [name, setName] = useState(board.name);
  const [isEntityFilterEnabled, setIsEntityFilterEnabled] = useState<boolean>(
    !!board.isEntityFilterEnabled,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onSave = () => {
    setIsLoading(true);
    const newBoard: CustomerLaneBoard = {
      ...board,
      name,
      isEntityFilterEnabled,
    };
    updateBoard(newBoard, accountRef).then(() => {
      setIsLoading(false);
      close();
    });
  };

  return (
    <SettingsPopup
      name={name}
      onNameChanged={onNameChanged}
      isEntityFilterEnabled={isEntityFilterEnabled}
      setIsEntityFilterEnabled={setIsEntityFilterEnabled}
      onSave={onSave}
      isLoading={isLoading}
      close={close}
    />
  );
};

const Gate = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  const { board } = useContext(BoardContext);
  if (isCustomerLaneBoard(board)) {
    return (
      <ModalTransition>
        {isOpen && (
          <ModalDialog
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <SettingsPopupContainer close={close} board={board} />
          </ModalDialog>
        )}
      </ModalTransition>
    );
  }

  return null;
};

export default Gate;
