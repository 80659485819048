import React, { useState } from 'react';
import OrderDetailsTabs from './OrderDetailsTabs';

const OrderDetailsTabsContainer = () => {
  const [selectedTab, setSelectedTab] = useState<OrderDetailsTab>('Summary');

  return (
    <OrderDetailsTabs
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    />
  );
};

export default OrderDetailsTabsContainer;
