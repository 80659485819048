import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';

import BaseViewsContext from 'contexts/BaseViewsContext';
import BoardContext from 'contexts/BoardContext';
import PerformanceBoardSettingsContext from '../../contexts/PerformanceBoardSettingsContext';
import BoardGeneralSettings from './BoardGeneralSettings';
import useEntityField from 'components/TargetManager/hooks/useEntityField';
import useUpdateGeneral from '../hooks/useUpdateGeneral';
import isPerformanceBoard from 'isPerformanceBoard';

const BoardGeneralSettingsContainer = ({
  board,
}: {
  board: PerformanceBoardTypes.Board;
}) => {
  const [title, setTitle] = useState<string>(board.name);
  const entityField = useEntityField(board.dataType);
  const { hasUnsavedChanges, setHasUnsavedChanges } = useContext(
    PerformanceBoardSettingsContext,
  );
  const [defaultSort, setDefaultSort] = useState<SortField>(
    board.defaultSort
      ? {
          field: board.defaultSort.field,
          sort: board.defaultSort.direction,
        }
      : {
          field: entityField || '',
          sort: 'asc' as 'asc',
        },
  );
  const [plates, setPlates] = useState<FilterPlateType[]>(() => board.plates);
  const [isEntityFilterEnabled, setIsEntityFilterEnabled] = useState<boolean>(
    () => {
      if (board) {
        if (board.isEntityFilterEnabled === undefined) {
          return false;
        }
        return board.isEntityFilterEnabled;
      }
      return false;
    },
  );

  useEffect(() => {
    if (
      title !== board.name ||
      (board.defaultSort &&
        !_.isEqual(
          { direction: defaultSort.sort, field: defaultSort.field },
          board.defaultSort,
        )) ||
      !_.isEqual(plates, board.plates) ||
      isEntityFilterEnabled !== board.isEntityFilterEnabled
    ) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [
    board.defaultSort,
    board.isEntityFilterEnabled,
    board.name,
    board.plates,
    defaultSort.field,
    defaultSort.sort,
    isEntityFilterEnabled,
    plates,
    setHasUnsavedChanges,
    title,
  ]);

  const { baseViews } = useContext(BaseViewsContext);
  const baseView = baseViews[board.dataType];
  const updateGeneral = useUpdateGeneral();

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  const fieldOptions =
    baseView && baseView.fieldOrder
      ? baseView.fieldOrder.map((field) => ({
          label: field,
          value: field,
          isSelected: defaultSort.field === field,
          onSelected: () => {
            setDefaultSort({ ...defaultSort, field });
          },
        }))
      : undefined;

  const getSortCode = ({ sort }: { sort: string }) => {
    return sort === 'Ascending' ? ('asc' as 'asc') : ('desc' as 'desc');
  };

  const sortOptions = ['Ascending', 'Descending'].map((sort) => ({
    label: sort,
    value: sort,
    isSelected: defaultSort.sort === getSortCode({ sort }),
    onSelected: () => {
      setDefaultSort({ ...defaultSort, sort: getSortCode({ sort }) });
    },
  }));

  const handleSave = useCallback(() => {
    updateGeneral({
      name: title,
      field: defaultSort.field,
      direction: defaultSort.sort,
      plates: plates,
      isEntityFilterEnabled,
    });
  }, [
    defaultSort.field,
    defaultSort.sort,
    isEntityFilterEnabled,
    plates,
    title,
    updateGeneral,
  ]);

  return (
    <BoardGeneralSettings
      board={board}
      baseView={baseView}
      title={title}
      onTitleChanged={onTitleChanged}
      fieldOptions={fieldOptions}
      sortOptions={sortOptions}
      plates={plates}
      setPlates={setPlates}
      hasUnsavedChanges={hasUnsavedChanges}
      handleSave={handleSave}
      isEntityFilterEnabled={isEntityFilterEnabled}
      setIsEntityFilterEnabled={setIsEntityFilterEnabled}
    />
  );
};

const Gate = () => {
  const { board } = useContext(BoardContext);
  if (isPerformanceBoard(board)) {
    return <BoardGeneralSettingsContainer board={board} />;
  } else {
    return null;
  }
};

export default Gate;
