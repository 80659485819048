import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import usePopup from '../../hooks/usePopup';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import ContextMenuContext from '../../kingpin/atoms/ContextMenu/ContextMenuContext';
import InlineDialog from '../InlineDialog';
import { ContextMenuDiv, Option } from '../../kingpin/atoms/ContextMenu';
import { ModalTransition } from '@atlaskit/modal-dialog';
import ContactUsFormContainer from '../ContactUsForm';
import NavSection from '../../kingpin/navigation/NavSection';
import Icon from '../../kingpin/atoms/Icon';
import Typography from '../../kingpin/atoms/Typography';
import NavSideBarContext from '../../contexts/NavSideBarContext';

const HELP_SITE = 'https://help.fleetops.com/';

export const HelpCenterButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  padding: 8px 0px;
`;
export const HelpCenterCustomButton = styled.div<{ navIsOpen: boolean }>`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  align-content: space-between;
  align-self: stretch;
  gap: 6px;
  height: 36px;
  padding-left: ${(props) => (props.navIsOpen ? '10px' : '6px')};
  border-radius: 6px;
  margin-bottom: 2px;
  background-color: #f1f1f1;
  cursor: pointer;
  border: 1px solid #dbdbdb;
  &:hover {
    background-color: #eaeaea;
  }
`;
const HelpCenterButton = () => {
  const { isOpen, open, close } = usePopup();
  const { isOpen: navIsOpen } = useContext(NavSideBarContext);
  const [contactUsIsOpen, setContactUsIsOpen] = useState<boolean>(false);
  const { trackEvent } = useContext(AnalyticsContext);

  const onHelpClicked = useCallback(() => {
    window.open(HELP_SITE, '_blank');
    trackEvent('Help - Contact Form Opened');
    close();
  }, [close, trackEvent]);

  const onContactSupportClicked = useCallback(() => {
    setContactUsIsOpen(true);
    close();
  }, [close]);

  return (
    <HelpCenterButtonWrapper>
      <NavSection hideBorderBottom>
        <ContextMenuContext.Provider
          value={{
            closeMenu: close,
            hideMenu: close,
          }}
        >
          <InlineDialog
            content={
              <ContextMenuDiv>
                <Option
                  icon="open-link"
                  label={'Help Center'}
                  onClick={onHelpClicked}
                />
                <Option
                  icon="mail"
                  label="Contact Support"
                  onClick={onContactSupportClicked}
                />
              </ContextMenuDiv>
            }
            placement={'top-start'}
            isOpen={isOpen}
            onClose={close}
          >
            <HelpCenterCustomButton
              data-testid={'help-button'}
              onClick={open}
              navIsOpen={navIsOpen}
            >
              <Icon icon="help-filled" color="#Df3d55" width={16} height={16} />
              {navIsOpen && (
                <Typography.Body type="Button Text">Support</Typography.Body>
              )}
            </HelpCenterCustomButton>
          </InlineDialog>
        </ContextMenuContext.Provider>
      </NavSection>
      <ModalTransition>
        {contactUsIsOpen && (
          <ContactUsFormContainer close={() => setContactUsIsOpen(false)} />
        )}
      </ModalTransition>
    </HelpCenterButtonWrapper>
  );
};

export default HelpCenterButton;
