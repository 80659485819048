import React, { useCallback, useContext, useEffect, useState } from 'react';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import UsersContext from '../../../contexts/UsersContext';
import userTypeCheckers from './UsersProvider/userTypeCheckers';
import { LOCAL_STORAGE_IMPERSONATING_USER_ID } from '../../../constants';
import getLocalStorageImpersonatorId from './getLocalStorageImpersonatorId';

const ImpersonatorProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { allUsers } = useContext(UsersContext);
  const [impersonatorId, setImpersonatorId] = useState<string | undefined>(
    getLocalStorageImpersonatorId() || undefined,
  );
  const [viewingAppAs, setViewingAppAs] = useState<
    UserManagement.SignedUpUser | UserManagement.PendingUser | undefined
  >();
  useEffect(() => {
    if (!impersonatorId) {
      setViewingAppAs(undefined);
      return;
    }

    const viewingAs = allUsers
      .filter(userTypeCheckers.isSignedUpOrPendingUser)
      .find((u) => u.id === impersonatorId);
    if (!viewingAs) {
      setViewingAppAs(undefined);
      return;
    }

    setViewingAppAs(viewingAs);
  }, [allUsers, impersonatorId]);

  const startImpersonationAs = useCallback((userId: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_IMPERSONATING_USER_ID, userId);
    setImpersonatorId(userId);
  }, []);

  const stopImpersonation = useCallback(() => {
    window.localStorage.removeItem(LOCAL_STORAGE_IMPERSONATING_USER_ID);
    setImpersonatorId(undefined);
  }, []);

  return (
    <ImpersonatorContext.Provider
      value={{
        impersonatorId,
        startImpersonationAs,
        stopImpersonation,
        viewingAppAs,
      }}
    >
      {children}
    </ImpersonatorContext.Provider>
  );
};

export default ImpersonatorProvider;
