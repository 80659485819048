import useGrantablePermissions from './useGrantablePermissions';
import useIsDataPermissionsEnabled from './useIsDataPermissionsEnabled';
import PERMISSIONS from '../../permissionDefinitions';
import { useCallback, useEffect, useState } from 'react';

// What permissions should be enabled by default?
// This is a different concern from which ones can be granted.
const useDefaultPermissions = () => {
  const isDataPermissionsEnabled = useIsDataPermissionsEnabled();
  const { configPermissions } = useGrantablePermissions({
    isUserShowScreen: false,
  });

  const getDefaultPermissions = useCallback(() => {
    if (isDataPermissionsEnabled) {
      return configPermissions
        .filter((p) => p.isEnabledByDefault)
        .map((p) => p.permission);
    }

    return [
      ...configPermissions,
      {
        permission: PERMISSIONS.VIEW_DATA.VIEW_REVENUE,
        isEnabledByDefault: true,
      },
      {
        permission: PERMISSIONS.VIEW_DATA.VIEW_ACCIDENTS,
        isEnabledByDefault: true,
      },
    ]
      .filter((p) => p.isEnabledByDefault)
      .map((p) => p.permission);
  }, [configPermissions, isDataPermissionsEnabled]);

  const [defaultPermissions, setDefaultPermissions] = useState<
    FleetOps.Permission[]
  >(() => getDefaultPermissions());

  useEffect(() => {
    setDefaultPermissions(getDefaultPermissions());
  }, [getDefaultPermissions]);

  return defaultPermissions;
};

export default useDefaultPermissions;
