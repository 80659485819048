import React, { useContext } from 'react';
import HeadingIcon from 'images/timeline-comments.svg';

import Divider from '../Common/Card/Divider';
import Heading from '../OrderDetailsTimeline/Heading';
import Comments from '../Comments';
import { buildShowOrderDetails } from '../../navigation/appRoutes';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const OrderDetailsComments = ({ orderNumber }: { orderNumber: string }) => {
  const { selectedPortal } = useContext(PortalsContext);
  return (
    <div>
      <Divider />
      <div>
        <Heading label="Comments" iconSrc={HeadingIcon} />
      </div>
      <div style={{ marginTop: 8 }}>
        <Comments
          commentableId={orderNumber}
          commentableType="Order"
          emailLink={buildShowOrderDetails(orderNumber, selectedPortal)}
          mentionedEmailBody={`mentioned you on an order - ${orderNumber}`}
          watcherEmailBody={`comments on an order - ${orderNumber}`}
        />
      </div>
    </div>
  );
};

export default OrderDetailsComments;
