import React from 'react';

import styled from 'styled-components';
import OrderDetailsComments from '../../components/OrderDetailsComments';
import OrderDetailsTimeline from '../../components/OrderDetailsTimeline';
import OrderDetailsTabs from '../../components/OrderDetailsTabs';
import OrderDetailsMap from '../../components/OrderDetailsMap';
import OrderDetailsTopBar from '../../components/OrderDetailsTopBar';

const Screen = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 16px;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
`;

const Left = styled.div`
  display: flex;
  margin-right: 32px;
  flex: 10;
  flex-direction: column;
`;

const Right = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
`;

const RightInner = styled.div`
  overflow-y: scroll;
`;

const OrderDetails = () => (
  <Screen>
    <Content>
      <Left>
        <OrderDetailsTopBar />
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <OrderDetailsMap />
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <OrderDetailsTabs />
        </div>
      </Left>
      <Right>
        <RightInner className="hiding-scrollbar">
          <OrderDetailsTimeline />
          <OrderDetailsComments />
        </RightInner>
      </Right>
    </Content>
  </Screen>
);

export default OrderDetails;
