import React from 'react';

import Typography from '../../../../../kingpin/atoms/Typography';
import Card from '../../../../Common/Card';
import ListItemWrapper from '../../../../../screens/DataManager/DatasetDefinitions/ListItemWrapper';
import Column from '../../../../../screens/DataManager/DatasetDefinitions/Column';
import { Link } from 'react-router-dom';
import { buildShowEntityApp } from '../../../../../navigation/appRoutes';

const EntityAppUsage = ({
  entities,
  usedId,
}: {
  entities: EntityDetails.Entity[];
  usedId: string;
}) => {
  return (
    <>
      {entities.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: '8px' }}>
            <Typography.Header type="H5">{`Entities (${entities.length})`}</Typography.Header>
          </div>
          <Card>
            {entities.map((g, index) => (
              <ListItemWrapper
                key={`${g.id}-${usedId}`}
                isLast={entities.length - 1 === index}
              >
                <Column
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <Link
                    to={buildShowEntityApp({ field: g.primaryField })}
                    target="_blank"
                    style={{ display: 'flex' }}
                  >
                    <Typography.Body type="Body 12" color="#0041ea">
                      {g.name}
                    </Typography.Body>
                  </Link>
                </Column>
              </ListItemWrapper>
            ))}
          </Card>
        </div>
      )}
    </>
  );
};

export default EntityAppUsage;
