import React from 'react';
import styled, { css } from 'styled-components';

import Summary from './Summary';
import OrderTab from './OrderTab';
import useGetTMS from './hooks/useGetTMS';
import Colors from '../../theme/colors';

const TabsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;

const selectedTabItemCss = css`
  color: #6264a7;
  border-bottom: 3px solid #6264a7;

  &:hover {
    opacity: 0.7;
  }
`;

interface TabItemProps {
  isSelected?: boolean;
}
const TabItem = styled.div<TabItemProps>`
  margin-right: 12px;
  padding-bottom: 8px;
  color: ${Colors.TEXT};
  ${(props) => props.isSelected && selectedTabItemCss};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    border-bottom: 3px solid #6264a7;
  }
`;

const OrderDetailsTabs = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: OrderDetailsTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<OrderDetailsTab>>;
}) => {
  const { orders } = useGetTMS();
  return (
    <React.Fragment>
      <TabsRow style={{ marginBottom: 8 }}>
        {(['Summary', ...orders] as OrderDetailsTab[]).map((t) => (
          <TabItem
            isSelected={selectedTab === t}
            onClick={() => setSelectedTab(t)}
            key={t}
          >
            {t}
          </TabItem>
        ))}
      </TabsRow>
      {selectedTab === 'Summary' && <Summary />}
      {selectedTab !== 'Summary' && <OrderTab tab={selectedTab} />}
    </React.Fragment>
  );
};

export default OrderDetailsTabs;
