import React, { useContext, useEffect } from 'react';
import FeatureGatesContext from 'contexts/FeatureGatesContext';
import usePopup from 'hooks/usePopup';
import { ModalTransition } from '@atlaskit/modal-dialog';

import IntegrationCard, { LoadingCard } from '../../IntegrationCard';
import ManageGroupsPopup from '../ManageGroupsPopup';
import MicrosoftIcon from '../MicrosoftIcon';
import useConfiguration from '../hooks/useConfiguration';
import ConsentLink from './ConsentLink';

const MicrosoftIntegrationCardContainer = () => {
  const { isMicrosoftSsoEnabled } = useContext(FeatureGatesContext);
  const { config, isLoading, refreshConfig } = useConfiguration();
  const { isOpen, open, close } = usePopup();

  useEffect(() => {
    if (!config || config.status !== 'pending permission grant') {
      return;
    }

    document.addEventListener('visibilitychange', refreshConfig);
    return () => {
      document.removeEventListener('visibilitychange', refreshConfig);
    };
  }, [config, refreshConfig]);

  if (isLoading) {
    return <LoadingCard />;
  }

  const MicrosoftCard = (
    <IntegrationCard
      onClick={
        config && config.status === 'pending permission grant'
          ? window.tokenFunction
          : open
      }
      config={config}
      title={'Microsoft'}
      icon={<MicrosoftIcon />}
      description="Integrate Microsoft SSO to allow users to sign into FleetOps using their Microsoft account"
    />
  );

  if (
    isMicrosoftSsoEnabled &&
    config &&
    config.status === 'pending permission grant'
  ) {
    return <ConsentLink>{MicrosoftCard}</ConsentLink>;
  }

  if (!config) {
    return <>{MicrosoftCard}</>;
  }

  return (
    <>
      {MicrosoftCard}
      <ModalTransition>
        {isOpen && (
          <ManageGroupsPopup
            close={close}
            initialGroups={config.permittedGroups}
            refreshConfig={refreshConfig}
            isSsoActive={config.status === 'active'}
          />
        )}
      </ModalTransition>
    </>
  );
};

export default MicrosoftIntegrationCardContainer;
