import { isEqual } from 'lodash';

const getIsFilterEqual = (a: FixedFilter, b: FixedFilter): boolean => {
  const aClone = {
    ...a,
    keywordValues: 'keywordValues' in a ? a.keywordValues.sort() : undefined,
  } as any;
  delete aClone['id'];
  delete aClone['mode'];
  delete aClone['appliedByInteractionWithChartDefId'];

  const bClone = {
    ...b,
    keywordValues: 'keywordValues' in b ? b.keywordValues.sort() : undefined,
  } as any;
  delete bClone['id'];
  delete bClone['mode'];
  delete bClone['appliedByInteractionWithChartDefId'];

  return isEqual(aClone, bClone);
};

export default getIsFilterEqual;
