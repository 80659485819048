import React from 'react';

interface AnalyticsContextType {
  trackEvent: (
    eventType: Analytics.EventType,
    eventProperties?: { [key: string]: string | undefined },
  ) => void;
  recordInHotjar: (event: string, data: object) => void;
}

const AnalyticsContext = React.createContext<AnalyticsContextType>({
  trackEvent: () => {},
  recordInHotjar: () => {},
} as AnalyticsContextType);

export default AnalyticsContext;
