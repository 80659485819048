import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import usePopup from 'hooks/usePopup';
import Colors2 from 'theme/Colors2';
import colors from 'theme/colors';
import Icon from 'kingpin/atoms/Icon';
import TextInput from 'kingpin/atoms/TextInput';
import Button from 'kingpin/atoms/Button';

import InlineDialog from '../InlineDialog';
import Row from '../Common/Row';

const aguid = require('aguid');

const NoBorderCss = css`
  border: unset;
`;

export const DropDownButtonDiv = styled.div<{
  isOpen: boolean;
  isDisabled?: boolean;
  unsetWidth?: boolean;
  isShort?: boolean;
  isPlaceholder?: boolean;
  hasError?: boolean;
  noBorders?: boolean;
  noRightBorder?: boolean;
  size?: 'Small' | 'Medium';
}>`
  height: ${(props) => (props.size && props.size === 'Medium' ? 36 : 32)}px;
  padding: 6px 12px;
  box-shadow:
    0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);

  border-radius: 8px;

  width: ${(props) => (props.unsetWidth ? 'unset' : '100%')};
  background-color: ${(props) =>
    props.hasError ? Colors2.Secondary.errorBackground : 'white'};
  border: 1px solid
    ${(props) => (props.hasError ? Colors2.Secondary.error : '#E0E0E0')};
  ${(props) => props.noBorders && NoBorderCss}

  border-top-right-radius: ${(props) =>
    props.noRightBorder ? '0' : undefined};
  border-bottom-right-radius: ${(props) =>
    props.noRightBorder ? '0' : undefined};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    user-select: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  ${(props) =>
    props.isOpen &&
    `
    border: 1px solid ${Colors2.Secondary.info} !important;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.12), 0px 0px 0px 1px rgba(63, 63, 68, 0.07);
  `}
  ${(props) =>
    props.isDisabled &&
    `
    background-color: #E9E9E9;
    cursor: not-allowed;
  `}
  
  ${(props) =>
    props.isPlaceholder &&
    `
  &:hover {
    border: 1px solid ${Colors2.Grey['7']};
    opacity: 0.6;
  }
  `}
`;

export const List = styled.div`
  padding: 8px;
  max-height: 300px;
  overflow-y: auto;
`;

const SearchWrapper = styled.div`
  padding: 8px 8px 0px 8px;
`;

export const ListItem = styled.div<{
  isKeyboardFocus?: boolean;
  isDisabled?: boolean;
}>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  cursor: pointer;

  span {
    user-select: none;
  }

  ${(props) =>
    props.isKeyboardFocus && `background-color: ${colors.SATURATED_NAVY_BLUE};`}

  &:hover {
    background: ${Colors2.Grey['9']};
  }

  ${(props) =>
    props.isDisabled &&
    `
    cursor: not-allowed;
    background-color: ${Colors2.Grey['8']};
    
    &:hover {
       background: ${Colors2.Grey['8']};
    }
  `}
`;

export const DropdownButton = ({
  label,
  isOpen,
  open,
  close,
  isDisabled,
  renderSelectedLabel,
  selectedLabel,
  placeholder,
  selectedArrow,
  testId,
  unsetWidth,
  isPlaceholder,
  isShort,
  hasError,
  onClearClicked,
  noBorders,
  noRightBorder,
  fullWidth,
  buttonStyle,
  size,
}: {
  label?: string;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  selectedLabel?: string;
  renderSelectedLabel?: () => JSX.Element;
  selectedArrow?: 'up' | 'down';
  placeholder?: string;
  isDisabled?: boolean;
  testId?: string;
  unsetWidth?: boolean;
  isPlaceholder?: boolean;
  isShort?: boolean;
  hasError?: boolean;
  onClearClicked?: () => void;
  noBorders?: boolean;
  noRightBorder?: boolean;
  fullWidth?: boolean;
  buttonStyle?: React.CSSProperties;
  size?: 'Small' | 'Medium';
}) => (
  <DropDownButtonDiv
    data-testid={testId}
    isOpen={isOpen}
    isDisabled={isDisabled}
    onClick={() => {
      if (isDisabled) {
        return;
      }

      if (isOpen) {
        close();
        return;
      }
      open();
    }}
    unsetWidth={unsetWidth}
    isPlaceholder={isPlaceholder}
    isShort={isShort}
    hasError={hasError}
    noBorders={noBorders}
    style={buttonStyle}
    noRightBorder={noRightBorder}
    size={size}
  >
    <Row
      style={{
        width: 'calc(100% - 16px)',
      }}
      centerAlign
      spaceBetween
    >
      {!!label && label !== '' && (
        <div style={{ marginRight: 8 }}>
          <Typography.Body
            type="Placeholder"
            color={isPlaceholder ? Colors2.Grey['5'] : Colors2.Grey['1']}
            isEllipsis
          >
            {label}
          </Typography.Body>
        </div>
      )}
      {renderSelectedLabel ? (
        <>{renderSelectedLabel()}</>
      ) : (
        <Typography.Body
          type={'Placeholder'}
          color={isPlaceholder ? Colors2.Grey['5'] : Colors2.Grey['1']}
          isEllipsis
        >
          {selectedLabel || placeholder}
        </Typography.Body>
      )}
    </Row>
    <Row centerAlign>
      {onClearClicked && (
        <Button
          icon="cross"
          onClick={onClearClicked}
          type="Ghost"
          size="Small"
        />
      )}
      <div style={{ marginLeft: 8, display: 'flex' }}>
        {!!selectedArrow && (
          <Icon icon={selectedArrow === 'up' ? 'arrow-up' : 'arrow-down'} />
        )}
        {!selectedArrow && isOpen && (
          <Icon
            icon={'chevron-up'}
            color={isPlaceholder ? Colors2.Grey['5'] : '#352D43'}
          />
        )}
        {!selectedArrow && !isOpen && (
          <Icon
            icon={'chevron-down'}
            color={isPlaceholder ? Colors2.Grey['5'] : '#352D43'}
          />
        )}
      </div>
    </Row>
  </DropDownButtonDiv>
);

const useFilteredOptions = ({
  options,
  controlledSearchText,
  controlledOnSearchTextChanged,
}: {
  options: DropdownOption[];
  controlledSearchText?: string;
  controlledOnSearchTextChanged?: (
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );
  const [filteredOptions, setFilteredOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const activeSearchText = controlledSearchText
      ? controlledSearchText
      : searchText;
    if (activeSearchText === '') {
      setFilteredOptions(options);
      return;
    }

    setFilteredOptions(
      options.filter((o) =>
        o.label.toLowerCase().includes(activeSearchText.toLowerCase()),
      ),
    );
  }, [controlledSearchText, options, searchText]);

  return {
    filteredOptions,
    searchText: controlledSearchText ? controlledSearchText : searchText,
    onSearchTextChanged: controlledOnSearchTextChanged
      ? controlledOnSearchTextChanged
      : onSearchTextChanged,
  };
};

const Dropdown = ({
  label,
  selectedLabel,
  selectedValue,
  renderSelectedLabel,
  selectedArrow,
  placeholder,
  options,
  isDisabled,
  listWidth,
  fullWidth,
  isMulti,
  onOpen,
  onClose,
  testId,
  unsetWidth,
  onClearClicked,
  isPlaceholder,
  isShort,
  tabIndex,
  hasError,
  defaultScrollToLabel,
  isSearchEnabled,
  searchPlaceholder,
  noBorders,
  noRightBorder,
  buttonStyle,
  controlledSearchText,
  controlledOnSearchTextChanged,
  isLoading,
  size,
}: {
  label?: string;
  selectedLabel?: string;
  selectedValue?: string;
  renderSelectedLabel?: () => JSX.Element;
  selectedArrow?: 'up' | 'down';
  placeholder?: string;
  options: DropdownOption[];
  isDisabled?: boolean;
  listWidth?: number;
  fullWidth?: boolean;
  isMulti?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  testId?: string;
  unsetWidth?: boolean;
  onClearClicked?: () => void;
  isPlaceholder?: boolean;
  isShort?: boolean;
  tabIndex?: string;
  hasError?: boolean;
  defaultScrollToLabel?: string;
  isSearchEnabled?: boolean;
  searchPlaceholder?: string;
  noBorders?: boolean;
  noRightBorder?: boolean;
  buttonStyle?: React.CSSProperties;
  controlledSearchText?: string;
  controlledOnSearchTextChanged?: (
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
  isLoading?: boolean;
  size?: 'Small' | 'Medium';
}) => {
  const { searchText, onSearchTextChanged, filteredOptions } =
    useFilteredOptions({
      options,
      controlledSearchText,
      controlledOnSearchTextChanged,
    });
  const [id] = useState<string>(aguid());
  const isSelected = useCallback(
    (option: DropdownOption): boolean => {
      if (option.isSelected) {
        return true;
      }

      if (
        !isMulti &&
        !option.arrow &&
        option.label === selectedLabel &&
        !isPlaceholder
      ) {
        return true;
      }

      if (!!selectedValue && option.value === selectedValue) {
        return true;
      }

      return false;
    },
    [isMulti, isPlaceholder, selectedLabel, selectedValue],
  );
  const [selectedLabelToUse, setSelectedLabelToUse] = useState<
    string | undefined
  >(selectedLabel);
  useEffect(() => {
    if (selectedLabel) {
      setSelectedLabelToUse(selectedLabel);
      return;
    }

    if (isMulti) {
      const selectedOptions = options.filter(isSelected);
      if (selectedOptions.length === 0) {
        return setSelectedLabelToUse(undefined);
      } else if (selectedOptions.length === 1) {
        return setSelectedLabelToUse(selectedOptions[0].label);
      } else if (selectedOptions.length === 2) {
        return setSelectedLabelToUse(
          `${selectedOptions[0].label} and ${selectedOptions[1].label}`,
        );
      } else if (selectedOptions.length === 3) {
        return setSelectedLabelToUse(
          `${selectedOptions[0].label}, ${selectedOptions[1].label} and 1 other`,
        );
      } else {
        return setSelectedLabelToUse(
          `${selectedOptions[0].label}, ${selectedOptions[1].label} and ${
            selectedOptions.length - 2
          } others`,
        );
      }
    } else {
      const o = options.find(isSelected);
      setSelectedLabelToUse(o ? o.label || o.value : undefined);
    }
  }, [isSelected, selectedLabel, options, isMulti]);

  const { isOpen, open: openPopup, close: closePopup } = usePopup();
  const getDefaultCursorIndex = useCallback(() => {
    const selectedOption = filteredOptions.findIndex(isSelected);
    if (selectedOption >= 0) {
      return selectedOption;
    }

    return -1;
  }, [isSelected, filteredOptions]);
  const [cursorIndex, setCursorIndex] = useState<number>(0);

  const open = useCallback(() => {
    setCursorIndex(getDefaultCursorIndex());
    openPopup();
    if (onOpen) {
      onOpen();
    }
  }, [getDefaultCursorIndex, onOpen, openPopup]);

  const close = useCallback(() => {
    closePopup();
    if (onClose) {
      onClose();
    }
  }, [closePopup, onClose]);

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'ArrowUp') {
        setCursorIndex((currentIndex) => {
          if (currentIndex === 0) {
            return filteredOptions.length - 1;
          } else {
            return currentIndex - 1;
          }
        });
      } else if (event.code === 'ArrowDown') {
        setCursorIndex((currentIndex) => {
          if (currentIndex === filteredOptions.length - 1) {
            return 0;
          } else {
            return currentIndex + 1;
          }
        });
      } else if (event.code === 'Enter') {
        filteredOptions[cursorIndex].onSelected();
        if (tabIndex) {
          const nextIndex = (Number.parseInt(tabIndex, 10) + 1).toString();
          const inputs = document.getElementsByTagName('input');
          let inputToFocus;
          for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i];
            if (input.getAttribute('tabindex') === nextIndex) {
              inputToFocus = input;
            }
          }
          if (inputToFocus) {
            inputToFocus.focus();
          }
        }
        if (!isMulti) {
          close();
        }
      }
    },
    [filteredOptions, cursorIndex, tabIndex, isMulti, close],
  );

  useEffect(() => {
    if (isOpen && !isSearchEnabled) {
      const focusedItem = filteredOptions[cursorIndex];
      if (!focusedItem) {
        return;
      }
      const element = document.getElementById(`${id}-${focusedItem.label}`);
      if (element && !!element.scrollIntoView) {
        element.scrollIntoView({ block: 'center' });
      }
    }
  }, [cursorIndex, id, isOpen, filteredOptions, isSearchEnabled]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keyup', onKeyUp);

      return () => {
        window.removeEventListener('keyup', onKeyUp);
      };
    }
  }, [isOpen, onKeyUp]);

  useEffect(() => {
    if (isOpen && !searchText && !isMulti) {
      const selectedOption = filteredOptions.find(isSelected);
      if (!selectedOption) {
        const defaultOptionItem = document.getElementById(
          `${id}-${defaultScrollToLabel}`,
        );
        if (defaultOptionItem && defaultOptionItem.scrollIntoView) {
          defaultOptionItem.scrollIntoView({ block: 'center' });
          const newCursorIndex = filteredOptions.findIndex(
            (o) => o.label === defaultScrollToLabel,
          );
          setCursorIndex(newCursorIndex);
        }
        return;
      }

      const selectedOptionItem = document.getElementById(
        `${id}-${selectedOption.label}`,
      );
      if (selectedOptionItem && selectedOptionItem.scrollIntoView) {
        selectedOptionItem.scrollIntoView({ block: 'center' });
      }
    }
  }, [
    defaultScrollToLabel,
    id,
    isOpen,
    isSelected,
    filteredOptions,
    selectedLabel,
    searchText,
    isMulti,
  ]);

  const hasSelectedOption = filteredOptions.some(isSelected);

  return (
    <InlineDialog
      isOpen={isOpen}
      onClose={close}
      content={
        <div>
          {isSearchEnabled && (
            <SearchWrapper>
              <TextInput
                placeholder={searchPlaceholder}
                value={searchText}
                onChange={onSearchTextChanged}
                icon="search"
                inputSize="Small"
                isLoading={isLoading}
                autoFocus
              />
            </SearchWrapper>
          )}
          <List>
            {filteredOptions.map((o, index) => (
              <ListItem
                key={o.key ? o.key : o.label}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  o.onSelected();

                  if (!isMulti) {
                    close();
                  }
                }}
                id={`${id}-${o.label}`}
                data-testid={
                  o.value ? `select-${o.value}` : `select-${o.label}`
                }
                isKeyboardFocus={cursorIndex === index}
              >
                {o.renderLabel && o.renderLabel()}
                {!o.renderLabel && (
                  <div>
                    <Typography.Body type="Label" isEllipsis>
                      {o.label}
                    </Typography.Body>
                    {o.subLabel && (
                      <div>
                        <Typography.Body
                          type="Label"
                          isEllipsis
                          color={Colors2.Grey['4']}
                        >
                          {o.subLabel}
                        </Typography.Body>
                      </div>
                    )}
                  </div>
                )}
                <div style={{ marginLeft: 8, display: 'flex' }}>
                  {o.renderRight && o.renderRight()}
                  {!o.renderRight && (
                    <>
                      {!!o.arrow && (
                        <Icon
                          icon={o.arrow === 'up' ? 'arrow-up' : 'arrow-down'}
                        />
                      )}
                      {isSelected(o) && <Icon icon={'checkmark'} />}
                      {!isSelected(o) && (
                        <div style={{ visibility: 'hidden' }}>
                          {/* This prevents a jump when nothing is selected */}
                          <Icon icon={'checkmark'} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </ListItem>
            ))}
          </List>
        </div>
      }
    >
      <DropdownButton
        noBorders={noBorders}
        noRightBorder={noRightBorder}
        testId={testId}
        label={label}
        open={open}
        isOpen={isOpen}
        close={close}
        selectedLabel={selectedLabelToUse}
        renderSelectedLabel={renderSelectedLabel}
        selectedArrow={selectedArrow}
        placeholder={placeholder}
        isDisabled={isDisabled}
        unsetWidth={unsetWidth}
        isPlaceholder={(isPlaceholder || !hasSelectedOption) && !selectedLabel}
        isShort={isShort}
        hasError={hasError}
        onClearClicked={
          onClearClicked && hasSelectedOption ? onClearClicked : undefined
        }
        fullWidth={fullWidth}
        buttonStyle={buttonStyle}
        size={size}
      />
    </InlineDialog>
  );
};

export default Dropdown;
