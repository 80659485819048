import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';

const OrderDetailsTopBar = ({
  orderNumber,
  dateRange,
}: {
  orderNumber: string;
  dateRange: string;
}) => (
  <Row centerAlign style={{ minHeight: 40 }} spaceBetween>
    <div style={{ marginBottom: 4 }}>
      <Typography.Header type={'H5'}>
        {`Order #${orderNumber}`}
      </Typography.Header>
    </div>
    <Typography.Body type={'Body 14'}>{dateRange}</Typography.Body>
  </Row>
);

export default OrderDetailsTopBar;
