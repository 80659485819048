import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import 'firebase/auth';
import * as Sentry from '@sentry/browser';
import CurrentUserContext, {
  CurrentUserContextType,
} from 'contexts/CurrentUserContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import AuthenticationContext from 'contexts/AuthenticationContext';
import Loading from 'components/Loading';
import USER_ROLES from 'roles';

import userTypeCheckers from './UsersProvider/userTypeCheckers';
import captureException from '../../../services/captureException';
import FlexCentered from '../../../components/Common/FlexCentered';
import STORE from '../../../store';

const useUser = (uid: string | undefined) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [staffUser, setStaffUser] = useState<
    UserManagement.FleetOpsStaffUser | undefined
  >();
  const [normalUser, setNormalUser] = useState<
    | UserManagement.SignedUpUser
    | UserManagement.PendingUser
    | UserManagement.WallboardUser
    | undefined
  >();

  useEffect(() => {
    if (!uid) {
      setStaffUser(undefined);
      setNormalUser(undefined);
      return;
    }

    let isActive = true;
    const getStaffUserListener = STORE.users
      .getFleetOpsStaffUsersRef({})
      .doc(uid)
      .onSnapshot((doc) => {
        if (isActive) {
          setStaffUser(doc.data());
        }
      });
    const getNormalUserListener = STORE.users
      .getUsersRef({ accountId: selectedAccountId })
      .doc(uid)
      .onSnapshot((doc) => {
        if (isActive) {
          setNormalUser(doc.data());
        }
      });

    return () => {
      isActive = false;
      getStaffUserListener();
      getNormalUserListener();
    };
  }, [selectedAccountId, uid]);

  const user = useMemo(() => {
    if (staffUser) {
      return staffUser;
    }

    return normalUser;
  }, [normalUser, staffUser]);

  return user;
};

const CurrentUserProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { uid, isSuperAdmin } = useContext(AuthenticationContext);
  const { selectedAccount } = useContext(AccountPickerContext);

  const [currentUser, setCurrentUser] = useState<
    CurrentUserContextType | undefined
  >();
  const userDoc = useUser(uid);

  const onPendingUserSignedIn = useCallback(
    (doc: UserManagement.PendingUser) => {
      const newDoc: UserManagement.SignedUpUser = {
        ...doc,
        type: 'SignedUp',
      };
      STORE.users
        .getUsersRef({ accountId: selectedAccount.accountId })
        .doc(newDoc.id)
        .set(newDoc);
    },
    [selectedAccount.accountId],
  );

  const configureSentry = useCallback((user: UserManagement.User) => {
    Sentry.configureScope((scope) => {
      const email = userTypeCheckers.isNotWallboardUser(user)
        ? user.email
        : 'NA';
      const displayName = userTypeCheckers.isNotWallboardUser(user)
        ? user.displayName
        : undefined;
      const wallboardId = userTypeCheckers.isWallboardUser(user)
        ? user.wallboardId
        : undefined;

      scope.setUser({
        id: user.id,
        email,
        username: displayName,
      });
      if (wallboardId) {
        scope.setExtra('Wallboard id', wallboardId);
      }
    });
  }, []);

  useEffect(() => {
    if (!userDoc) {
      setCurrentUser(undefined);
      return;
    } else if (userTypeCheckers.isSignedUpUser(userDoc)) {
      setCurrentUser({
        id: userDoc.id,
        displayName: userDoc.displayName,
        email: userDoc.email,
        isAdmin: selectedAccount.roles.some((r) => r === USER_ROLES.ADMIN),
        createdOn: userDoc.createdOn,
        ui: userDoc.ui,
      });
    } else if (userTypeCheckers.isFleetOpsStaffUser(userDoc)) {
      setCurrentUser({
        id: userDoc.id,
        displayName: userDoc.displayName,
        email: userDoc.email,
        isAdmin: true,
        isFleetOpsStaff: true,
        createdOn: userDoc.createdOn,
        isSuperAdmin,
        ui: userDoc.ui,
      });
    } else if (userTypeCheckers.isWallboardUser(userDoc)) {
      setCurrentUser({
        id: userDoc.id,
        displayName: 'Wallboard',
        wallBoardId: userDoc.wallboardId,
        createdOn: userDoc.createdOn,
        isWallboardUser: true,
      });
    } else if (userTypeCheckers.isPendingUser(userDoc)) {
      setCurrentUser(undefined);
      onPendingUserSignedIn(userDoc);
    } else {
      const e = new Error();
      e.name = `Unexpected user type for user uid: ${uid}`;
      captureException(e);
      setCurrentUser(undefined);
      return;
    }

    configureSentry(userDoc);
  }, [
    configureSentry,
    isSuperAdmin,
    onPendingUserSignedIn,
    selectedAccount.roles,
    uid,
    userDoc,
  ]);

  if (!currentUser) {
    return (
      <FlexCentered style={{ height: '100%' }}>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
